import qs from 'qs';
import { ChangeEvent, ReactNode } from 'react';
import { FaCaretRight, FaCaretLeft } from 'react-icons/fa6';
import { useSearchParams } from 'react-router-dom';

import { PAGE_SIZE } from 'app/configs';
import { TABLE_FOOTER_HEIGHT } from 'app/constants';
import cn from 'app/utils/ClassName';
import { formatNumber } from 'app/utils/Common';

import Button from '../Button';

interface Props {
  count: number;
  page: number;
  pageSize?: number;
  extraInfo?: ReactNode;
  onChange?: (page: number) => void;
}

interface TempFilter {
  [x: string]: string | undefined | number;
}

export const TableFooter = ({
  count,
  page,
  pageSize,
  extraInfo,
  onChange,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const size = pageSize || PAGE_SIZE;
  const totalPage = count ? Math.ceil(count / size) : 1;

  const nextPageOnClick = () => {
    if (onChange) {
      onChange(page + 1);
    } else {
      const queries = qs.parse(searchParams.toString());
      const tempFilter: TempFilter = {
        ...queries,
        page: page + 1,
      };
      const newQueries = qs.stringify(tempFilter);
      setSearchParams(newQueries);
    }
  };

  const prevPageOnClick = () => {
    if (onChange) {
      onChange(page - 1);
    } else {
      const queries = qs.parse(searchParams.toString());
      const tempFilter: TempFilter = {
        ...queries,
        page: page - 1,
      };
      const newQueries = qs.stringify(tempFilter);
      setSearchParams(newQueries);
    }
  };

  const pageOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(parseInt(e.target.value, 10));
    } else {
      const queries = qs.parse(searchParams.toString());
      const tempFilter: TempFilter = {
        ...queries,
        page: e.target.value,
      };
      const newQueries = qs.stringify(tempFilter);
      setSearchParams(newQueries);
    }
  };

  return (
    <div
      className="flex items-center justify-between border-t px-4 py-2"
      style={{ height: TABLE_FOOTER_HEIGHT }}
    >
      <div className="flex items-center gap-[8px]">
        共有
        {formatNumber(count)}
        項
        {' '}
        <div>{extraInfo}</div>
      </div>

      <div className="flex items-center justify-items-center gap-1">
        <Button
          type="button"
          variant="text"
          color="primary"
          containerStyle={cn(
            'p-2',
            page === 1
              ? 'cursor-not-allowed text-text-light'
              : 'text-text-secondary hover:text-brand-primary',
          )}
          disabled={page === 1}
          onClick={prevPageOnClick}
        >
          <FaCaretLeft className="text-text-primary" />
        </Button>
        <select
          value={page}
          onChange={pageOnChange}
          className="w-[80px] appearance-none text-center font-semibold ring-0"
        >
          {Array.from({ length: totalPage }, (_, i) => (
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
        <Button
          type="button"
          variant="text"
          color="primary"
          containerStyle={cn(
            'p-2',
            page === totalPage
              ? 'cursor-not-allowed text-text-light'
              : 'text-text-secondary hover:text-brand-primary',
          )}
          disabled={page === totalPage}
          onClick={nextPageOnClick}
        >
          <FaCaretRight className="text-text-primary" />
        </Button>
      </div>
    </div>
  );
};
