import dayjs from 'dayjs';
import { z } from 'zod';

import { FormPreviewItem, PublishStatusType } from 'app/models/Common';

export const COMPANY_ACTIVITY_FORM_SCHEMA = z
  .object({
    title: z
      .string()
      .min(1, '必填')
      .max(200, '超出字數限制'),
    content: z
      .string()
      .min(1, '必填'),
    images: z
      .array(z
        .custom<FormPreviewItem>()
        .or(z.instanceof(File)))
      .min(1, '最少一張'),
    companyEventStartDate: z
      .date()
      .nullable()
      .refine((companyEventStartDate) => companyEventStartDate, '必填'),
    companyEventEndDate: z
      .date()
      .nullable()
      .refine((companyEventEndDate) => companyEventEndDate, '必填'),
    isOnline: z
      .boolean(),
    zoomRoomNumber: z
      .string(),
    zoomRoomPassword: z
      .string(),
    publishStatus: z
      .custom<PublishStatusType>(),
    publishedDate: z
      .date()
      .nullable(),
  })
  .refine(({ isOnline, zoomRoomNumber }) => !isOnline || zoomRoomNumber, {
    message: '必填',
    path: ['zoomRoomNumber'],
  })
  .refine(({ isOnline, zoomRoomPassword }) => !isOnline || zoomRoomPassword, {
    message: '必填',
    path: ['zoomRoomPassword'],
  })
  .refine(({
    companyEventStartDate, companyEventEndDate,
  }) => dayjs(companyEventStartDate).isBefore(companyEventEndDate), {
    message: '結束時間不能比開始時間早',
    path: ['companyEventEndDate'],
  });

export type CompanyActivityFormDataType = z.infer<typeof COMPANY_ACTIVITY_FORM_SCHEMA>;

export const COMPANY_ACTIVITY_LIST_SORT_OPTION_MAP = {
  createdAt: '建立日期',
};
