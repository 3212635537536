/* eslint-disable react/button-has-type */
import React, { ReactNode, ForwardRefRenderFunction } from 'react';
import { Link } from 'react-router-dom';

import { HrefType } from 'app/models/Common';
import { handleHref } from 'app/utils';
import cn from 'app/utils/ClassName';

type ButtonType = 'button' | 'submit' | 'reset';

interface ButtonProps {
  children: ReactNode;
  color: 'primary' | 'secondary' | 'attention' | 'grey';
  variant: 'text' | 'contained' | 'outlined';
  containerStyle?: string;
  type?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  href?: HrefType;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  tooltipId?: string;
  buttonId?: string;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = ({
  children,
  variant,
  color,
  containerStyle,
  type = 'button',
  loading,
  disabled,
  href,
  onClick,
  tooltipId,
  buttonId,
}, ref) => {
  const getTextColor = (): string => {
    switch (color) {
      case 'primary':
        return 'text-brand-primary';
      case 'secondary':
        return 'text-brand-secondary';
      case 'attention':
        return 'text-brand-attention';
      case 'grey':
        return 'text-text-grey';
      default:
        return '';
    }
  };

  const getBorderColor = (): string => {
    switch (color) {
      case 'primary':
        return 'border-brand-primary';
      case 'secondary':
        return 'border-brand-secondary';
      case 'attention':
        return 'border-brand-attention';
      case 'grey':
        return 'border-text-grey';
      default:
        return '';
    }
  };

  const getBGColor = (): string => {
    switch (color) {
      case 'primary':
        return 'bg-brand-primary hover:brightness-110';
      case 'secondary':
        return 'bg-brand-secondary hover:brightness-125';
      case 'attention':
        return 'bg-brand-attention hover:brightness-125';
      case 'grey':
        return 'bg-text-grey hover:brightness-125';
      default:
        return '';
    }
  };

  const getHoverColor = (): string => {
    switch (color) {
      case 'primary':
        return 'hover:bg-brand-primary/10';
      case 'secondary':
        return 'hover:bg-brand-secondary/20';
      case 'attention':
        return 'hover:bg-brand-attention/20';
      case 'grey':
        return 'hover:bg-text-grey/20';
      default:
        return '';
    }
  };

  const getClassName = (): string => {
    switch (variant) {
      case 'text':
        return `bg-app-white ${getTextColor()} ${getHoverColor()}`;
      case 'outlined':
        return `bg-app-white border ${getTextColor()} ${getBorderColor()} ${getHoverColor()}`;
      case 'contained':
        return `${getBGColor()} text-white ${getBorderColor()} shadow-button`;
      default:
        return '';
    }
  };

  if (href) {
    return (
      <Link
        to={handleHref(href)}
        className={cn(
          'rounded transition duration-150 ease-in-out',
          getClassName(),
          disabled && 'cursor-not-allowed',
          containerStyle,
        )}
      >
        {loading ? (
          <div className="size-[20px] animate-spin rounded-full border-2 border-gray-300 border-t-white" />
        ) : (
          children
        )}
      </Link>
    );
  }

  return (
    <button
      className={cn(
        'rounded transition duration-150 ease-in-out',
        getClassName(),
        disabled && 'cursor-not-allowed',
        containerStyle,
      )}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      ref={ref}
      id={buttonId}
      data-tooltip-id={tooltipId}
    >
      {loading ? (
        <div className="size-[20px] animate-spin rounded-full border-2 border-gray-300 border-t-white" />
      ) : (
        children
      )}
    </button>
  );
};

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button);
