import { Filter, ListResult } from 'app/models/Common';
import {
  FromSVType, ShopCategoryResponse, ShopDepartmentType, ShopResponse,
} from 'app/models/Shop';

import Axios from '.';

/**
 * List All Shop Category (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/shop/category)
 */
export const getShopCategoryList = async () => {
  const { data } = await Axios.get<ShopCategoryResponse[]>('/commerces/shop/category');
  return data;
};

/*
 * List All Shop (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/shop)
 */

export interface GetShopListParams extends Filter<ShopResponse> {
  shopCategoryId?: number;
  shopTitle?: string;
  fromSV?: FromSVType;
  shopType?: ShopDepartmentType;
  shopId?: number;
}

export const getShopList = async (params: GetShopListParams) => {
  const { data } = await Axios.get<ListResult<ShopResponse>>('/commerces/shop', { params });
  return data;
};

/*
 *  Update Shop Ordering in Batch, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/shop/batchOrdering)
 */

export interface BatchUpdateShopParams {
  Shops : {
    shopId: number;
    ordering: number;
  }[];
}

export const batchUpdateShop = async (params: BatchUpdateShopParams) => {
  const { data } = await Axios.post('/commerces/shop/batchOrdering', params);
  return data;
};

/*
 * Get One Shop (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/shop/-shopId-)
*/

export const getOneShop = async (shopId: number) => {
  const { data } = await Axios.get<ShopResponse>(`/commerces/shop/${shopId}`);
  return data;
};

/*
 * Create A Shop, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/shop)
 */

export interface CreateOneShopParams {
  shopCategoryId: number | null;
  shopTitle: string;
  shopDescription: string;
  shopProfilePic: string;
  shopBanner: string;
  phone: string
  email: string
  website: string
  location: string

}

export const createOneShop = async (params: CreateOneShopParams) => {
  const { data } = await Axios.post<ShopResponse>('/commerces/shop', params);
  return data;
};

/*
 * Update One Shop By ShopId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#patch-/v2/commerces/shop/-shopId-)
 */

export interface UpdateOneShopParams {
  shopCategoryId?: number | null;
  shopTitle?: string;
  shopDescription?: string;
  shopProfilePic?: string;
  shopBanner?: string;
  phone?: string
  email?: string
  website?: string
  location?: string
}

export const updateOneShop = async (shopId: number, params: UpdateOneShopParams) => {
  const { data } = await Axios.patch<ShopResponse>(`/commerces/shop/${shopId}`, params);
  return data;
};

/**
 * Delete One Shop By ShopId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#delete-/v2/commerces/shop/-shopId-)
 */

export const deleteOneShop = async (shopId: number) => {
  await Axios.delete(`/commerces/shop/${shopId}`);
};

/**
 * Update or Create Shop Categories in Batch, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/shop/category/batchCreate)
 */

export interface BatchUpdateOrCreateShopCategoryParams {
  ShopCategories: {
    shopCategoryId: number,
    category: string,
    ordering: number,
  }[]
}

export const batchUpdateOrCreateShopCategory = async (params : BatchUpdateOrCreateShopCategoryParams) => {
  const { data } = await Axios.post<ShopCategoryResponse>('/commerces/shop/category/batchCreate', params);
  return data;
};

/**
 * Create A Shop Category, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/shop/category)
 */

export interface CreateOneShopCategoryParams {
  category: string;
  ordering: number;
}

export const createOneShopCategory = async (params : CreateOneShopCategoryParams) => {
  const { data } = await Axios.post<ShopCategoryResponse>('/commerces/shop/category', params);
  return data;
};

/**
 * Update One Shop Category By shopCategoryId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#patch-/v2/commerces/shop/category/-shopCategoryId-)
 */

export interface UpdateOneShopCategoryParams {
  category: string;
  ordering: number;
}

export const updateOneShopCategory = async (shopCategoryId : number, params : UpdateOneShopCategoryParams) => {
  const { data } = await Axios.patch<ShopCategoryResponse>(`/commerces/shop/category/${shopCategoryId}`, params);
  return data;
};

/**
 * Delete One Shop Category By shopCategoryId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#delete-/v2/commerces/shop/category/-shopCategoryId-)
 */

export const deleteOneShopCategory = async (shopCategoryId : number) => {
  await Axios.delete(`/commerces/shop/category/${shopCategoryId}`);
};

/**
 * List All Featured Shop (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/shop/featured)
 */

export const getFeaturedShopList = async () => {
  const { data } = await Axios.get<ShopResponse[]>('/commerces/shop/featured');
  return data;
};

/**
 * Create A Featured Shop, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/shop/featured)
 */

export interface CreateOneFeaturedShopParams {
  shopId: number;
}

export const createOneFeaturedShop = async (params : CreateOneFeaturedShopParams) => {
  const { data } = await Axios.post<ShopResponse>('/commerces/shop/featured', params);
  return data;
};

/**
 * Delete One Featured Shop By featuredShopId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#delete-/v2/commerces/shop/featured/-featuredShopId-)
 */

export const deleteOneFeaturedShop = async (featuredShopId : number) => {
  await Axios.delete(`/commerces/shop/featured/${featuredShopId}`);
};

/**
 * Update Featured Shops Ordering in Batch, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/shop/featured/batchOrdering)
 */

export interface BatchUpdateFeaturedShopOrderingParams {
  FeaturedShops: {
    featuredShopId: number,
    ordering: number,
  }[]
}

export const batchUpdateFeaturedShopOrdering = async (params : BatchUpdateFeaturedShopOrderingParams) => {
  const { data } = await Axios.post<ShopResponse>('/commerces/shop/featured/batchOrdering', params);
  return data;
};
