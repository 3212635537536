import qs from 'qs';

import { GetPerformanceListParams } from 'app/apis/PerformanceServices';
import { GetStatementListParams } from 'app/apis/StatementServices';
import { GetSurveyResponseListParams } from 'app/apis/SurveyServices';
import { GetTransactionListParams } from 'app/apis/TransactionServices';

export const ROUTES = {
  HOME: '/',

  // admins
  ADMINS: '/admins',
  ADMINS_NEW: '/admins/new',
  ADMINS_EDIT: (id: number) => `/admins/edit/${id}`,
  ADMINS_UPDATE_PASSWORD: (id: number) => `/admins/edit/${id}/password`,

  // users
  USERS: '/users/list',
  USERS_DETAIL: (id: number) => `/users/list/${id}/detail`,
  USERS_NEW: '/users/list/new',
  USERS_EDIT: (id: number) => `/users/list/edit/${id}`,
  USERS_INVITATION: '/users/list/invitation',
  USERS_INVITATION_NEW: '/users/list/invitation/new',

  // performances
  PERFORMANCE: '/performance',
  PERFORMANCE_LIST: (params?: Partial<GetPerformanceListParams>) => `/performance/list?${qs.stringify(params)}`,
  PERFORMANCE_NEW: '/performance/list/new',
  PERFORMANCE_EDIT: (id: number | string) => `/performance/list/edit/${id}`,
  PERFORMANCE_RANKING_LIST: '/performance/ranking/list',
  // user tags
  USER_TAGS_LIST: '/users/tags/list',
  USER_TAGS_NEW: '/users/tags/list/new',
  USER_TAGS_EDIT: (tagName: string) => `/users/edit/${tagName}`,

  // info
  INFO: '/info',
  INFO_LIST: '/info/list',
  INFO_LIST_NEW: '/info/list/new',
  INFO_LIST_SORT: '/info/sort',
  INFO_LIST_EDIT: (id: number) => `/info/list/edit/${id}`,
  INFO_LIST_COPY: (id: number) => `/info/list/copy/${id}`,

  // contact
  CONTACT: '/contact',
  CONTACT_LIST: '/contact/list',
  CONTACT_NEW: '/contact/list/new',
  CONTACT_EDIT: (id: number) => `/contact/list/edit/${id}`,
  CONTACT_LIST_EDIT: (id: number) => `/contact/list/edit/${id}`,
  CONTACT_LIST_COPY: (id: number) => `/contact/list/copy/${id}`,
  CONTACT_CATEGORY_SORT: '/contact/category/sort',
  CONTACT_CATEGORY_CONTACT: 'contact/category/contact',
  CONTACT_VIEW_BASE: '/contact/view/list',
  CONTACT_VIEW: (id: number) => `/contact/view/list/${id}`,
  CONTACT_SORT_BASE: '/contact/list/contact-sort',
  CONTACT_SORT: (id: number) => `/contact/list/contact-sort/${id}`,

  // maintenance
  MAINTENANCE: '/maintenance',

  // events
  EVENTS: '/events',
  EVENTS_LIST: '/events/list',
  EVENTS_NEW: '/events/list/new',
  EVENTS_EDIT: (id: number) => `/events/list/edit/${id}`,
  EVENTS_COPY: (id: number) => `/events/list/copy/${id}`,
  EVENTS_VIEW: (id: number) => `/events/list/view/${id}`,
  EVENTS_CATEGORY: '/events/categories',

  // scheduled notification
  NOTIFICATIONS_LIST: '/notifications/list',
  NOTIFICATIONS_NEW: '/notifications/list/new',
  NOTIFICATIONS_EDIT: (id: number) => `/notifications/list/edit/${id}`,

  // company activity
  COMPANY_ACTIVITY_LIST: '/company-activity/list',
  COMPANY_ACTIVITY_LIST_NEW: '/company-activity/list/new',
  COMPANY_ACTIVITY_LIST_EDIT: (id: number) => `/company-activity/list/edit/${id}`,
  COMPANY_ACTIVITY_LIST_COPY: (id: number) => `/company-activity/list/copy/${id}`,

  // shop
  SHOP: '/shop',
  SHOP_LIST: '/shop/list',
  SHOP_NEW: '/shop/list/new',
  SHOP_EDIT: (id: number) => `/shop/list/edit/${id}`,
  SHOP_SORT: '/shop/sort',
  SHOP_CATEGORY_SORT: '/shop/category/sort',
  SHOP_FEATURED_SORT: '/shop/featured/sort',

  // featured hash tag
  HASH_TAG: '/hashTag',
  FEATURED_HASH_TAG_SORT: '/hashTag/featured/sort',

  // commerce item
  COMMERCE_ITEM: '/commerceItem',
  COMMERCE_ITEM_CATEGORY_SORT: '/commerceItem/category/sort',

  // banner
  BANNER_LIST: '/banners/list',
  BANNER_NEW: '/banners/list/new',
  BANNER_EDIT: (id: number) => `/banners/list/edit/${id}`,
  BANNER_COPY: (id: number) => `/banners/list/copy/${id}`,
  BANNER_LIST_SORT: '/banners/sort',

  // pop up banner
  POP_UP_BANNER_FORM: '/popUpBanner',

  // statement `/health-record/list?${qs.stringify(params)}`
  STATEMENTS_LIST: (params?: Partial<GetStatementListParams>) => `/statements/list?${qs.stringify(params)}`,
  STATEMENTS_DETAIL_BASIC: '/statements/detail',
  STATEMENTS_DETAIL: (id: number) => `/statements/detail/${id}`,
  STATEMENTS_NEW: '/statements/list/new',
  STATEMENTS_EDIT: (id: number) => `/statements/list/edit/${id}`,
  STATEMENTS_COPY: (id: number) => `/statements/list/copy/${id}`,

  // News
  NEWS_LIST: '/news/list',
  NEWS_NEW: '/news/list/new',
  NEWS_EDIT: (id: number) => `/news/list/edit/${id}`,
  NEWS_COPY: (id: number) => `/news/list/copy/${id}`,
  NEWS_VIEW: (id: number) => `/news/list/view/${id}`,

  // Product
  PRODUCT_LIST: '/product/list',
  PRODUCT_NEW: '/product/list/new',
  PRODUCT_EDIT: (id: number) => `/product/list/edit/${id}`,
  PRODUCT_COPY: (id: number) => `/product/list/copy/${id}`,
  PRODUCT_VIEW: (id: number) => `/product/list/view/${id}`,

  // Department
  DEPARTMENT: '/department',
  DEPARTMENT_SORT: '/department/sort',

  // Course
  COURSE_LIST: '/course/list',
  COURSE_NEW: '/course/list/new',
  COURSE_EDIT: (id: number) => `/course/list/edit/${id}`,
  COURSE_COPY: (id: number) => `/course/list/copy/${id}`,
  COURSE_VIEW: (id: number) => `/course/list/view/${id}`,

  // transaction
  TRANSACTION_LIST: (params?: Partial<GetTransactionListParams>) => `/transactions/list?${qs.stringify(params)}`,

  // survey
  SURVEY_LIST: '/survey/list',
  SURVEY_NEW: '/survey/list/new',
  SURVEY_EDIT: (id: number) => `/survey/list/edit/${id}`,
  SURVEY_COPY: (id: number) => `/survey/list/copy/${id}`,

  // survey response
  SURVEY_RESPONSE_LIST:
  (params?: Partial<GetSurveyResponseListParams>) => `/survey/response/list?${qs.stringify(params)}`,
  SURVEY_RESPONSE_VIEW: (id: number) => `/survey/response/list/view/${id}`,

  // Commerce Banner
  COMMERCE_BANNER: '/commerceBanner',
  COMMERCE_BANNER_SORT: '/commerceBanner/sort',

  // instant event
  INSTANT_EVENT_LIST: '/instantEvent/list',

  // system config
  SYSTEM_CONFIG: '/system-config',

  // drive
  DRIVE: '/drive',

  CHANGE_PASSWORD: '/change-password',
  LOGIN: '/login',
  LOGOUT: '/logout',
  NOT_FOUND: '/not-found',
};
