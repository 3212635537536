import _ from 'lodash';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';

import { PAGE_SIZE } from 'app/configs';
import { Filter, ListResult, OptionItem } from 'app/models/Common';

export interface UseAutoCompleteParams<P extends Filter<R>, R> {
  getListCall: (_params: P) => Promise<ListResult<R>>;
  getItemCall: (id: number | string) => Promise<R>;
  searchTextFieldName: keyof P;
  labelRender: (item: R) => OptionItem;
  errorText?: string;
  initialParams?: P;
  preventFirstLoad?: boolean;
  selectedId?: number | string;
  enabled?: boolean;
}

export const useAutoComplete = <P extends Filter<R>, R>({
  getListCall,
  getItemCall,
  searchTextFieldName,
  labelRender,
  errorText,
  initialParams,
  preventFirstLoad,
  selectedId,
  enabled = true,
}: UseAutoCompleteParams<P, R>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<OptionItem[]>([]);
  const [count, setCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [filter, setFilter] = useState<P>(initialParams as P);
  const shouldAutoLoad = useRef(!preventFirstLoad);
  const firstLoaded = useRef<boolean>(false);
  const currentPage = useRef<number>(0);
  const currentHasNext = useRef<boolean>(false);

  useEffect(() => {
    currentPage.current = 0;
  }, [searchText]);

  const getData = useCallback(async () => {
    if (enabled) {
      try {
        setIsLoading(true);
        const dataRes = await getListCall({
          ...filter,
          [searchTextFieldName]: searchText.length > 0 ? searchText : undefined,
          limit: PAGE_SIZE,
        });
        setCount(dataRes.count);
        setDataList(dataRes.rows.map(labelRender));
        currentHasNext.current = dataRes.hasNext;
        if (!firstLoaded.current) {
          firstLoaded.current = true;
        }
      } catch (err) {
        toast.error('請檢查你的網絡。');
      } finally {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchText, enabled]);

  const updateDataListWithItemCall = useCallback(
    async (id: number | string) => {
      try {
        if (!dataList.some((data) => data.value === id)) {
          const dataRes = await getItemCall(id);
          setDataList((prev) => _.sortBy(
            _.uniqBy(
              [labelRender(dataRes), ...prev],
              (option) => option.value,
            ),
            (option) => -option.value,
          ));
        }
      } catch (err) {
        toast.error('請檢查你的網絡。');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataList],
  );

  useEffect(() => {
    if (selectedId) {
      updateDataListWithItemCall(selectedId);
    }
  }, [selectedId, updateDataListWithItemCall]);

  useEffect(() => {
    if (shouldAutoLoad.current === true) {
      getData();
    }
    shouldAutoLoad.current = true;
  }, [getData]);

  const dataScrollToBottom = async () => {
    let page = 0;
    if (dataList.length === 0) {
      page = 0;
    } else {
      page = currentPage.current + 1;
      currentPage.current = page;
    }

    try {
      if (currentHasNext.current) {
        setIsLoading(true);
        const dataRes = await getListCall({
          ...filter,
          [searchTextFieldName]: searchText,
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        });
        setDataList((prev) => _.sortBy(
          _.uniqBy(
            [...prev, ...dataRes.rows.map(labelRender)],
            (option) => option.value,
          ),
          (option) => -option.value,
        ));
        currentHasNext.current = dataRes.hasNext;
      }
    } catch (err) {
      toast.error(errorText || '加載列表失敗，請重試。');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    firstLoaded: firstLoaded.current,
    filter,
    setFilter,
    searchText,
    setSearchText,
    dataList,
    setDataList,
    count,
    updateDataListWithItemCall,
    onMenuScrollToBottom: dataScrollToBottom,
  } as const;
};
