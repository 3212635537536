import _ from 'lodash';
import qs from 'qs';
import {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';

import cn from 'app/utils/ClassName';

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  className?: string;
}

interface TempFilter {
  [x: string]: string | undefined | number;
}

export const SearchFilter = ({
  label, name, placeholder, className,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<string>(searchParams.get(name) || '');

  useEffect(() => {
    const queries = qs.parse(searchParams.toString()) as TempFilter;
    setValue(queries[name] as string ? queries[name] as string : '');
  }, [name, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceValueOnChange = useCallback(
    _.debounce((e: ChangeEvent<HTMLInputElement>) => {
      const queries = qs.parse(searchParams.toString()) as TempFilter;
      const tempFilter: TempFilter = {
        ...queries,
        [name]: e.target.value.toString() || undefined,
        page: 1,
      };
      const newQueries = qs.stringify(tempFilter);
      setSearchParams(newQueries);
    }, 300),
    [searchParams, setSearchParams],
  );

  return (
    <div className={cn('flex min-w-[150px] flex-col py-2', className)}>
      <label className="text-[14px] text-text-secondary">{label}</label>
      <div className="relative w-full">
        <input
          className="mt-1 w-full border-b border-border-brand pr-[22px] text-[14px] outline-none placeholder:text-text-light focus:border-brand-primary focus:outline-none"
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value);
            debounceValueOnChange(e);
          }}
        />
        <IoSearchOutline className="absolute right-1 top-1 size-4 text-text-light" />
      </div>

    </div>
  );
};
