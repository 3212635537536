import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  API_URL,
  CURRENT_ENV,
  VERSION_NUMBER,
} from 'app/configs';
import { useAuthStore } from 'app/stores';

interface ServerResponse {
  message?: string;
  name?: string;
}

const instance = axios.create({
  baseURL: `${API_URL}/v2/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 10000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { profile } = useAuthStore();

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse): AxiosResponse => response;

    const errInterceptor = (error: AxiosError<ServerResponse>): Promise<AxiosError> => {
      if (error?.response?.status === 401) {
        navigate('/logout');
      } else if (error?.response?.status === 403) {
        navigate('/logout');
      }
      if (error?.response?.status !== 401 && error?.response?.status !== 403) {
        const message = error?.response?.data?.message
          || error?.response?.data?.name
          || 'Message not returned';
        Sentry.captureException(
          new Error(
            `[UID: ${profile?.adminId || 'N/A'}]
              [V-${CURRENT_ENV}-${VERSION_NUMBER}]
              [STATUS: ${error?.response?.status}]
              ${message}
            `,
          ),
        );
      }
      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, [profile?.adminId, navigate]);

  return children;
};

export default instance;
export { AxiosInterceptor };
