import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  RowSelectionState,
} from '@tanstack/react-table';
import _ from 'lodash';
import React, {
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdOutlineManageSearch } from 'react-icons/md';

import cn from 'app/utils/ClassName';

interface CustomTableProps<T> {
  data: T[];
  defaultColumns: ColumnDef<T>[];
  rowSelection?: RowSelectionState;
  loading: boolean;
  className?: string;
  setRowSelection?: Dispatch<React.SetStateAction<RowSelectionState>>;
  setNewOrder?: (newOrderedData: T[]) => void;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const CustomTable = <T,>({
  data,
  defaultColumns,
  rowSelection,
  loading,
  className,
  setRowSelection,
  setNewOrder,
}: CustomTableProps<T>) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  // TODO: check what is this useState for
  const [columns] = React.useState<typeof defaultColumns>(() => [
    ...defaultColumns,
  ]);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    enableMultiRowSelection: true,
    state: rowSelection ? { rowSelection } : undefined,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (data && setRowSelection) {
      setRowSelection({});
    }
  }, [data, setRowSelection]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({ top: 0, behavior: 'instant' });
    }
  }, [data]);

  const debounceUpdateScrollValue = _.debounce((val) => {
    setScrollPosition(val);
  }, 100);

  const handleTableScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const { scrollTop } = target;
    debounceUpdateScrollValue(scrollTop);
  };

  return (
    <div
      ref={tableContainerRef}
      className={cn('block h-full max-w-full overflow-auto', className)}
      onScroll={handleTableScroll}
    >
      <table className="w-max min-w-full text-[16px]">
        <thead
          className={cn(
            'sticky top-0 bg-white',
            scrollPosition ? 'shadow-sm' : '',
          )}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="border-b">
              {setNewOrder && (
                <th className="group w-[15px] px-2 py-1 text-left font-semibold text-text-primary" />
              )}
              {headerGroup.headers.map((header) => (
                <th
                  className="group px-2 py-1 text-left font-semibold text-text-primary"
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ position: 'relative', width: header.getSize() }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  {header.column.getCanResize() && (
                    <div
                      role="presentation"
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      // eslint-disable-next-line tailwindcss/no-custom-classname
                      className={cn(
                        'resizer absolute right-0 top-0 h-full w-[3px]  cursor-col-resize touch-none select-none opacity-0',
                        header.column.getIsResizing()
                          ? 'bg-brand-primary opacity-100'
                          : 'bg-[#00000080]',
                        'group-hover:opacity-100',
                      )}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {!loading && data.length === 0 && (
            <tr>
              <td
                className="border-b border-border-brand p-2 text-center text-text-secondary"
                colSpan={table.getVisibleFlatColumns().length}
              >
                <div className="flex items-center justify-center">
                  <MdOutlineManageSearch className="mr-1 size-5 text-text-secondary" />
                  暫無資料，請嘗試其他篩選條件。
                </div>
              </td>
            </tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={cn(
                'border-b text-text-secondary last:border-b-0',
                'hover:bg-bg-row hover:shadow-md',
              )}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                  className="p-2 text-left"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
