import { Disclosure } from '@headlessui/react';
import { TbChevronRight } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';

import { SIDE_BAR_WIDTH } from 'app/constants';
import { MENUS } from 'app/constants/Menu';
import { SHOP_MENUS } from 'app/constants/ShopMenu';
import {
  useAuthStore, useCommerceItemStore, useLayoutStore, useShopStore,
} from 'app/stores';
import cn from 'app/utils/ClassName';

const NAV_FONT_STYLE_CLASS = 'flex w-full justify-between bg-bg-transparent py-3 text-left text-[14px] font-normal transition-colors duration-300';
const NAV_HOVER_FONT_STYLE_CLASS = 'hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75';

export const SideBar = ({ currentPath }: { currentPath: string }) => {
  const { shop } = useShopStore();
  const { isSideBarOpen } = useLayoutStore();
  const { profile } = useAuthStore();
  const {
    newsSubmittedCount,
    productSubmittedCount,
    eventSubmittedCount,
    courseSubmittedCount,
    newsModifyRequestCount,
    productModifyRequestCount,
    eventModifyRequestCount,
    courseModifyRequestCount,
  } = useCommerceItemStore();

  const hideOtherDisclosuresHandle = (id: string) => {
    if (document.querySelectorAll('button[data-headlessui-state="open"]')) {
      const buttons = Array.from(
        document.querySelectorAll('button[data-headlessui-state="open"]'),
      ) as HTMLButtonElement[];
      buttons.forEach((button) => {
        if (button?.id !== id) {
          button?.click();
        }
      });
    }
  };

  return (
    <div
      className={cn('h-full bg-white overflow-y-auto overflow-x-hidden', isSideBarOpen ? 'hidden non-mobile:block' : 'hidden')}
      style={{ minWidth: SIDE_BAR_WIDTH, width: SIDE_BAR_WIDTH }}
    >
      <div
        className="px-4 pb-4"
        style={{
          width: SIDE_BAR_WIDTH,
        }}
      >
        <div className="mx-auto w-full max-w-md bg-transparent">
          {currentPath
            && (shop ? SHOP_MENUS(shop.shopId) : MENUS)
              .filter((menu) => !('permissions' in menu)
               || (menu.permissions.length
                && menu.permissions.some((permissions) => permissions === profile?.role)))
              .map((menu) => ('children' in menu && menu.children ? (
                <Disclosure
                  key={menu.key}
                  defaultOpen={currentPath === menu.key}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        onClick={() => hideOtherDisclosuresHandle(menu.key)}
                        className={cn(
                          NAV_FONT_STYLE_CLASS,
                          NAV_HOVER_FONT_STYLE_CLASS,
                          `${
                            currentPath === menu.key
                              ? 'text-brand-primary'
                              : 'text-text-secondary'
                          }`,
                        )}
                      >
                        <div className="flex items-center">
                          {menu.icon}
                          {isSideBarOpen && (
                          <div className="ml-2">{menu.title}</div>
                          )}
                        </div>
                        {isSideBarOpen && (
                        <TbChevronRight
                          className={`${open ? 'rotate-90' : ''} size-5`}
                        />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className="flex flex-col pl-[28px] text-[14px] font-normal text-text-secondary">
                        {menu.children
                          .filter((children) => !('permissions' in children)
                          || (children.permissions?.length
                            && children.permissions.some((permissions) => permissions === profile?.role)))
                          .map((child) => (
                            <NavLink
                              key={child.key}
                              to={child.href}
                              className={({ isActive }) => cn(
                                NAV_FONT_STYLE_CLASS,
                                NAV_HOVER_FONT_STYLE_CLASS,
                                `${
                                  isActive
                                    ? 'text-brand-primary'
                                    : 'text-text-secondary'
                                }`,
                              )}
                            >
                              {child.title}
                            </NavLink>
                          ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ) : (
                <NavLink
                  key={menu.key}
                  to={menu.href}
                  onClick={() => hideOtherDisclosuresHandle('')}
                  className={({ isActive }) => cn(
                    NAV_FONT_STYLE_CLASS,
                    NAV_HOVER_FONT_STYLE_CLASS,
                    `${
                      isActive ? 'text-brand-primary' : 'text-text-secondary'
                    }`,
                  )}
                >
                  <div className="flex w-full items-center">
                    {menu.icon}
                    {isSideBarOpen && <div className="mx-2 flex-1">{menu.title}</div>}
                    <div className="flex items-center gap-[8px]">
                      {menu.key === 'news' && newsModifyRequestCount
                        ? (
                          <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                            {newsModifyRequestCount}
                          </div>
                        )
                        : null}
                      {menu.key === 'product' && productModifyRequestCount
                        ? (
                          <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                            {productModifyRequestCount}
                          </div>
                        )
                        : null}
                      {menu.key === 'events' && eventModifyRequestCount
                        ? (
                          <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                            {eventModifyRequestCount}
                          </div>
                        )
                        : null}
                      {menu.key === 'course' && courseModifyRequestCount
                        ? (
                          <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                            {courseModifyRequestCount}
                          </div>
                        )
                        : null}
                      {menu.key === 'news' && newsSubmittedCount
                        ? (
                          <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                            {newsSubmittedCount}
                          </div>
                        )
                        : null}
                      {menu.key === 'product' && productSubmittedCount
                        ? (
                          <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                            {productSubmittedCount}
                          </div>
                        )
                        : null}
                      {menu.key === 'events' && eventSubmittedCount
                        ? (
                          <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                            {eventSubmittedCount}
                          </div>
                        )
                        : null}
                      {menu.key === 'course' && courseSubmittedCount
                        ? (
                          <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                            {courseSubmittedCount}
                          </div>
                        )
                        : null}
                    </div>
                  </div>
                </NavLink>
              )))}
        </div>
      </div>
    </div>
  );
};
