import { Menu, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import { PiUsersThreeDuotone, PiDevToLogo } from 'react-icons/pi';
import { RiMenuUnfoldLine, RiMenuLine } from 'react-icons/ri';
import { SlClose, SlLogout } from 'react-icons/sl';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { countCommerceItem } from 'app/apis/CommerceServices';
import Logo from 'app/assets/images/logo.png';
import { Image } from 'app/components/Image';
import { CURRENT_ENV, VERSION_NUMBER } from 'app/configs';
import {
  HEADER_HEIGHT, ROUTES, SHOP_MODE_ROUTES, SIDE_BAR_WIDTH,
} from 'app/constants';
import { CommerceType } from 'app/models/CommerceItem';
import {
  useAuthStore, useCommerceItemStore, useLayoutStore, useShopStore,
} from 'app/stores';
import { getImageURL, isLocalhost } from 'app/utils';

import Button from '../Button';

export const Header = () => {
  const { shop, resetShop } = useShopStore();
  const navigate = useNavigate();
  const { profile } = useAuthStore();
  const {
    isSideBarOpen,
    isOnDevMode,
    toggleSideBar,
    openDrawer,
    toggleDevMode,
  } = useLayoutStore();
  const {
    updateNewsSubmittedCount,
    updateProductSubmittedCount,
    updateEventSubmittedCount,
    updateCourseSubmittedCount,
    updateNewsModifyRequestCount,
    updateProductModifyRequestCount,
    updateEventModifyRequestCount,
    updateCourseModifyRequestCount,
  } = useCommerceItemStore();

  const getCommerceItemSubmittedCount = useCallback(async (commerceType: CommerceType) => {
    const data = await countCommerceItem({
      limit: 5,
      offset: 0,
      commerceType,
      reviewStatus: '已提交',
      shopId: shop?.shopId,
    });
    return data.count;
  }, [shop?.shopId]);

  const getCommerceItemModifyRequestCount = useCallback(async (commerceType: CommerceType) => {
    const data = await countCommerceItem({
      limit: 5,
      offset: 0,
      commerceType,
      hasPendingApplyStatus: true,
      shopId: shop?.shopId,
    });
    return data.count;
  }, [shop?.shopId]);

  useEffect(() => {
    const getAllCommerceItemSubmittedCount = async () => {
      try {
        const newsSubmittedCount = await getCommerceItemSubmittedCount('資訊');
        updateNewsSubmittedCount(newsSubmittedCount);
        const productSubmittedCount = await getCommerceItemSubmittedCount('貨品');
        updateProductSubmittedCount(productSubmittedCount);
        const eventSubmittedCount = await getCommerceItemSubmittedCount('活動');
        updateEventSubmittedCount(eventSubmittedCount);
        const courseSubmittedCount = await getCommerceItemSubmittedCount('課程');
        updateCourseSubmittedCount(courseSubmittedCount);
        const newsModifyRequestCount = await getCommerceItemModifyRequestCount('資訊');
        updateNewsModifyRequestCount(newsModifyRequestCount);
        const productModifyRequestCount = await getCommerceItemModifyRequestCount('貨品');
        updateProductModifyRequestCount(productModifyRequestCount);
        const eventModifyRequestCount = await getCommerceItemModifyRequestCount('活動');
        updateEventModifyRequestCount(eventModifyRequestCount);
        const courseModifyRequestCount = await getCommerceItemModifyRequestCount('課程');
        updateCourseModifyRequestCount(courseModifyRequestCount);
      } catch {
        toast.error('獲取失敗');
      }
    };
    getAllCommerceItemSubmittedCount();

    const interval = setInterval(() => {
      getAllCommerceItemSubmittedCount();
    }, 60000);

    return () => clearInterval(interval);
  }, [
    getCommerceItemModifyRequestCount,
    getCommerceItemSubmittedCount,
    updateCourseModifyRequestCount,
    updateCourseSubmittedCount,
    updateEventModifyRequestCount,
    updateEventSubmittedCount,
    updateNewsModifyRequestCount,
    updateNewsSubmittedCount,
    updateProductModifyRequestCount,
    updateProductSubmittedCount,
  ]);

  return (
    <div
      className="flex items-center bg-white shadow-card"
      style={{ height: HEADER_HEIGHT }}
    >
      <Link
        to={shop ? SHOP_MODE_ROUTES(shop.shopId).EVENTS_LIST : ROUTES.HOME}
        style={{
          width: SIDE_BAR_WIDTH,
        }}
        className="hidden items-center justify-center non-mobile:flex"
      >
        <Image src={Logo} alt="logo" width={150} className="aspect-[150/21] w-[150px]" />
      </Link>
      <div className="flex flex-1 flex-row items-center justify-between">
        <div className="flex items-center">
          {shop && (
            <Menu as="div" className="relative">
              <Menu.Button className="flex select-none items-center gap-[4px]">
                <Image
                  src={getImageURL(shop.shopProfilePic)}
                  className="size-[24px] rounded-full"
                />
                <div className="leading-[16px]">{shop.shopTitle}</div>
              </Menu.Button>
              <Menu.Items className="absolute left-0 z-[1] mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <Menu.Item>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="group flex w-full items-center rounded-md p-2 text-sm hover:bg-white hover:text-brand-primary text-text-primary"
                    onClick={() => {
                      navigate(profile?.role === '超級管理員' ? ROUTES.USERS : ROUTES.EVENTS_LIST);
                      resetShop();
                    }}
                  >
                    <SlClose className="mr-1 size-4" />
                    離開商店Mode
                  </Button>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          )}

          <Button
            variant="text"
            color="primary"
            containerStyle="p-[4px] ml-[8px] hidden non-mobile:flex"
            onClick={() => {
              toggleSideBar();
            }}
          >
            {isSideBarOpen ? (
              <RiMenuLine className="size-5 text-text-primary" />
            ) : (
              <RiMenuUnfoldLine className="size-5 text-text-primary" />
            )}
          </Button>

          {isLocalhost() && (
            <Button
              variant="text"
              color="primary"
              containerStyle="p-[4px] ml-[8px] hidden non-mobile:flex"
              onClick={() => {
                toggleDevMode();
              }}
            >
              {isOnDevMode ? (
                <PiDevToLogo className="size-5 text-brand-primary" />
              ) : (
                <PiDevToLogo className="size-5 text-text-primary" />
              )}
            </Button>
          )}
        </div>

        <Button
          variant="text"
          color="primary"
          containerStyle="p-[4px] ml-[8px] non-mobile:hidden"
          onClick={() => {
            openDrawer();
          }}
        >
          <RiMenuLine className="size-5 text-text-primary" />
        </Button>

        <Menu as="div" className="relative z-10 inline-block text-left">
          <div className="flex h-full items-center">
            <Menu.Button className="flex w-full items-center justify-center rounded-md  px-4 text-sm font-medium text-text-primary focus:outline-none focus-visible:ring-2">
              <PiUsersThreeDuotone className="mr-1 size-5 text-text-primary" />
              {profile?.displayName}
              <FaChevronDown
                className="-mr-1 ml-2 size-3 text-text-primary"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="p-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={ROUTES.LOGOUT}
                      className={`${
                        active ? 'text-brand-primary' : 'text-text-primary'
                      } group flex w-full items-center rounded-md p-2 text-sm`}
                    >
                      <SlLogout className="mr-1 size-4" />
                      Logout
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="p-1">
                <div className="flex w-full items-center rounded-md p-2 text-sm text-text-secondary">
                  Ver:
                  {' '}
                  {CURRENT_ENV}
                  -
                  {VERSION_NUMBER}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
