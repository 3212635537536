import { TimeStamp } from './Common';
import { ShopResponse } from './Shop';
import { UserResponse } from './User';

export interface InstantEventResponse extends TimeStamp {
  instantEventId: number
  name: string
  startDate?: string | null
  endDate?: string | null
  allowReCheckIn: boolean
  shopId: number
  checkedInCount?: number | null
  Shop: ShopResponse;
  InstantEventAttendances: InstantEventAttendance[];
}

export interface InstantEventAttendance extends TimeStamp {
  instantEventAttendanceId: number
  instantEventId?: number | null
  userId?: number | null
  checkInDateTime?: string | null
  InstantEvent: InstantEventResponse
  User: UserResponse
}

export const INSTANT_EVENT_LIST_SORT_OPTION_MAP = {
  createdAt: '建立日期',
};
