import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { z } from 'zod';

import { validatePermission } from 'app/helpers/CommerceItem';
import {
  PaymentOptionType, ReviewStatusType, SurveyOption,
} from 'app/models/CommerceItem';
import { FormPreviewItem, PublishStatusType } from 'app/models/Common';
import { DriveType, FolderType } from 'app/models/Drive';

import { COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA, COMMERCE_ITEM_PERMISSION_FORM_SCHEMA } from './CommerceItem';

dayjs.extend(isSameOrBefore);
export const EVENT_BASIC_INFO_FORM_SCHEMA = z
  .object({
    title: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填')
      .max(200, '超出字數上限'),
    shopId: z
      .number({
        required_error: '必選',
      })
      .nullable()
      .refine((val) => val && val > 0, { message: '必選' }),
    commerceCategory: z
      .number({
        required_error: '必選',
      })
      .nullable()
      .refine((val) => val && val > 0, { message: '必選' }),
    CommerceItemHashTags: z
      .array(
        z.object({
          commerceItemHashTagId: z
            .number().optional(),
          hashTag: z
            .string().min(1, '必填'),
        }),
      ),
    coverPhoto: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
        {
          required_error: '必填',
        },
      )
      .min(1, '必填'),
    images: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
        {
          required_error: '必填',
        },
      ),
    subtitle: z
      .string()
      .max(200, '超出字數上限'),
    description: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填'),
    caution: z
      .string(),
    paidReminder: z
      .string(),
    attachment: z
      .object({
        path: z
          .string(),
        folderType: z
          .custom<FolderType>(),
        driveType: z
          .custom<DriveType>(),
        hiddenRoot: z
          .string(),
      }).nullable(),
    surveyId: z
      .number()
      .nullable(),
    publishStatus: z
      .custom<PublishStatusType>(),
    publishedDate: z
      .date()
      .nullable(),
    publishEndDate: z
      .date()
      .nullable(),
  })
  .refine(
    ({ publishStatus, publishedDate }) => publishStatus === 'Published' || publishedDate,
    { message: '請選擇發佈時間', path: ['publishDate'] },
  )
  .refine(
    ({ publishStatus, publishedDate, publishEndDate }) => !publishEndDate
    || dayjs(publishStatus === 'Unpublished' ? publishedDate || undefined : undefined).isSameOrBefore(dayjs(publishEndDate)),
    { message: '結束發佈時間不能比開始發佈時間早', path: ['publishEndDate'] },
  );

export type EventBasicInfoFormDataType = z.infer<typeof EVENT_BASIC_INFO_FORM_SCHEMA>;

export const INITIALIZED_EVENT_BASIC_INFO : EventBasicInfoFormDataType = {
  title: '',
  description: '',
  caution: '',
  paidReminder: '',
  attachment: null,
  publishStatus: 'Published',
  publishedDate: null,
  publishEndDate: null,
  coverPhoto: [],
  images: [],
  subtitle: '',
  shopId: null,
  commerceCategory: null,
  CommerceItemHashTags: [],
  surveyId: null,
};

export const EVENT_OTHER_INFO_FORM_SCHEMA = z
  .object({
    eventDetailId: z
      .number()
      .optional(),
    commerceScheduleId: z
      .number()
      .optional(),
    location: z
      .string()
      .max(200, '超出字數上限')
      .nullable(),
    openForSaleDate: z
      .date()
      .nullable()
      .refine((data) => data, { message: '必填' }),
    discontinueDate: z
      .date()
      .nullable()
      .refine((data) => data, { message: '必填' }),
    startDate: z
      .date()
      .nullable(),
    endDate: z
      .date()
      .nullable(),
    isOnline: z
      .boolean(),
    zoomRoomNumber: z
      .string()
      .max(30, '超出字數上限'),
    zoomRoomPassword: z
      .string()
      .max(30, '超出字數上限'),
    material: z
      .object({
        path: z
          .string(),
        folderType: z
          .custom<FolderType>(),
        driveType: z
          .custom<DriveType>(),
        hiddenRoot: z
          .string(),
      }).nullable(),
    surveyId: z
      .number()
      .nullable(),
  })
  .refine(({ startDate }) => startDate ?? 0, { message: '必填', path: ['startDate'] })
  .refine(({ endDate }) => endDate, { message: '必填', path: ['endDate'] })
  .refine(({ startDate, endDate }) => dayjs(startDate).isSameOrBefore(dayjs(endDate)), {
    message: '結束時間不能早於開始時間',
    path: ['endDate'],
  })
  .refine(({ startDate, openForSaleDate }) => dayjs(openForSaleDate).isSameOrBefore(dayjs(startDate), 'm'), {
    message: '開始時間不能早於開始報名日期',
    path: ['startDate'],
  })
  .refine(({ isOnline, location }) => isOnline || location, {
    message: '請填寫地址',
    path: ['location'],
  })
  .refine(({ isOnline, zoomRoomNumber }) => (isOnline ? Boolean(zoomRoomNumber) : true), {
    message: '請填寫Zoom房號',
    path: ['zoomRoomNumber'],
  })
  .refine(({ isOnline, zoomRoomPassword }) => (isOnline ? Boolean(zoomRoomPassword) : true), {
    message: '請填寫Zoom密碼',
    path: ['zoomRoomPassword'],
  })
  .refine(({ openForSaleDate, discontinueDate }) => dayjs(openForSaleDate).isSameOrBefore(dayjs(discontinueDate)), {
    message: '報名截止日期不能早於開始報名日期',
    path: ['discontinueDate'],
  });

export type EventOtherInfoFormDataType = z.infer<typeof EVENT_OTHER_INFO_FORM_SCHEMA>;

export const INITIALIZED_EVENT_OTHER_INFO : EventOtherInfoFormDataType = {
  eventDetailId: undefined,
  commerceScheduleId: undefined,
  location: '',
  openForSaleDate: null,
  discontinueDate: null,
  startDate: null,
  endDate: null,
  isOnline: false,
  zoomRoomNumber: '',
  zoomRoomPassword: '',
  material: null,
  surveyId: null,
};

export const EVENT_PAYMENT_FORM_SCHEMA = z
  .object({
    paymentOptions: z.array(
      z.object({
        commercePurchaseOptionId: z
          .number()
          .optional(),
        commercePurchaseOptionTitle: z
          .string({
            required_error: '必填',
          })
          .max(200, '超出字數上限'),
        commercePurchaseOptionSubtitle: z
          .string()
          .max(200, '超出字數上限'),
        image: z
          .array(
            z.instanceof(File)
              .or(z.custom<FormPreviewItem>()),
          ),
        paymentType: z
          .custom<PaymentOptionType>(),
        cash: z
          .string(),
        point: z
          .string(),
        commercePurchaseOptionQuota: z
          .string(),
        commercePurchaseOptionMin: z
          .string()
          .regex(/^\d*$/, '請輸入正整數')
          .or(z.literal('')),
        commercePurchaseOptionMax: z
          .string()
          .regex(/^[1-9]\d*$/, '請輸入正整數')
          .or(z.literal('')),
        commercePurchaseOptionPerUserMin: z
          .string()
          .regex(/^\d*$/, '請輸入正整數')
          .or(z.literal('')),
        commercePurchaseOptionPerUserMax: z
          .string()
          .regex(/^[1-9]\d*$/, '請輸入正整數')
          .or(z.literal('')),
        pointEarnAfterApply: z
          .string()
          .refine((val) => !val || parseInt(val, 10) > 0, '請輸入正數'),
        pointEarnAfterCheckIn: z
          .string()
          .refine((val) => !val || parseInt(val, 10) > 0, '請輸入正數'),
        isAllowGuest: z
          .boolean(),
        isAllowWaitList: z
          .boolean(),
        waitListQuota: z
          .string()
          .regex(/^[1-9]\d*$/, '請輸入正整數')
          .or(z.literal('')),
        surveyOption: z
          .custom<SurveyOption>(),
        surveyId: z
          .number()
          .nullable(),
      })
        .refine(({ waitListQuota, isAllowWaitList }) => !isAllowWaitList || waitListQuota, {
          message: '必填',
          path: ['waitListQuota'],
        })
        .refine(({ paymentType, cash }) => !(paymentType === 'cash' || paymentType === 'cashAndPoint') || Number(cash) >= 4, {
          message: '最低金額為HKD$4',
          path: ['cash'],
        })
        .refine(({ paymentType, point }) => !(paymentType === 'point' || paymentType === 'cashAndPoint') || Number(point) > 0, {
          message: '最低積分為1分',
          path: ['point'],
        })
        .refine((
          { commercePurchaseOptionMin, commercePurchaseOptionMax },
        ) => !commercePurchaseOptionMin
            || !commercePurchaseOptionMax
            || parseInt(commercePurchaseOptionMin, 10) <= parseInt(commercePurchaseOptionMax, 10), {
          message: '最少數量不能大於最多數量',
          path: ['commercePurchaseOptionMin'],
        })
        .refine((
          { commercePurchaseOptionPerUserMin, commercePurchaseOptionPerUserMax },
        ) => !commercePurchaseOptionPerUserMin
            || !commercePurchaseOptionPerUserMax
            || parseInt(commercePurchaseOptionPerUserMin, 10) <= parseInt(commercePurchaseOptionPerUserMax, 10), {
          message: '最少數量不能大於最多數量',
          path: ['commercePurchaseOptionPerUserMin'],
        })
      ,
    ),
    isCharity: z
      .boolean(),
    acceptDonation: z
      .boolean(),
    surveyId: z
      .number()
      .nullable(),
    perOrderMin: z
      .string()
      .regex(/^\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perOrderMax: z
      .string()
      .regex(/^[1-9]\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perUserTotalMin: z
      .string()
      .regex(/^\d*$/, '請輸入正整數')
      .or(z.literal('')),
    perUserTotalMax: z
      .string()
      .regex(/^[1-9]\d*$/, '請輸入正整數')
      .or(z.literal('')),
  })
  .refine((
    { perOrderMin, perOrderMax },
  ) => !perOrderMin
            || !perOrderMax
            || parseInt(perOrderMin, 10) <= parseInt(perOrderMax, 10), {
    message: '最少數量不能大於最多數量',
    path: ['perOrderMin'],
  })
  .refine(
    (
      { perUserTotalMin, perUserTotalMax },
    ) => !perUserTotalMin
            || !perUserTotalMax
            || parseInt(perUserTotalMin, 10)
  <= parseInt(perUserTotalMax, 10),
    {
      message: '最少數量不能大於最多數量',
      path: ['perUserTotalMin'],
    },

  );

type EventPaymentFormDataType = z.infer<typeof EVENT_PAYMENT_FORM_SCHEMA>;

export const INITIALIZED_EVENT_PAYMENT_OPTION: EventPaymentFormDataType['paymentOptions'][number] = {
  commercePurchaseOptionId: undefined,
  image: [],
  commercePurchaseOptionTitle: '',
  commercePurchaseOptionSubtitle: '',
  paymentType: 'free',
  cash: '',
  point: '',
  commercePurchaseOptionQuota: '',
  commercePurchaseOptionMin: '',
  commercePurchaseOptionMax: '',
  commercePurchaseOptionPerUserMin: '',
  commercePurchaseOptionPerUserMax: '',
  pointEarnAfterApply: '',
  pointEarnAfterCheckIn: '',
  isAllowGuest: false,
  isAllowWaitList: false,
  waitListQuota: '',
  surveyOption: 'Unessential',
  surveyId: null,
};

export const INITIALIZED_EVENT_PAYMENT: EventPaymentFormDataType = {
  paymentOptions: [INITIALIZED_EVENT_PAYMENT_OPTION],
  isCharity: false,
  acceptDonation: false,
  perOrderMin: '',
  perOrderMax: '',
  perUserTotalMin: '',
  perUserTotalMax: '',
  surveyId: null,
};

export const EVENT_FORM_SCHEMA = z
  .object({
    reviewStatus: z
      .custom<ReviewStatusType>()
      .optional(),
    remarks: z
      .string(),
    basicInfo: EVENT_BASIC_INFO_FORM_SCHEMA,
    otherInfo: EVENT_OTHER_INFO_FORM_SCHEMA,
    payment: EVENT_PAYMENT_FORM_SCHEMA,
    permission: COMMERCE_ITEM_PERMISSION_FORM_SCHEMA,
    pushNotification: COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA,
  })
  .superRefine(({
    reviewStatus, permission, payment: {
      paymentOptions,
    },
  }, ctx) => {
    if (reviewStatus !== '草稿') {
      paymentOptions.forEach((option, index) => {
        if (!option.commercePurchaseOptionTitle) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '必填',
            path: ['payment', 'paymentOptions', index, 'commercePurchaseOptionTitle'],
          });
        }
        if (!option.commercePurchaseOptionQuota) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '必填',
            path: ['payment', 'paymentOptions', index, 'commercePurchaseOptionQuota'],
          });
        } else if (!option.commercePurchaseOptionQuota.match(/^[1-9]\d*$/)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: '請輸入正整數',
            path: ['payment', 'paymentOptions', index, 'commercePurchaseOptionQuota'],
          });
        }
      });
      validatePermission(permission, ctx);
    }
  });

export type EventFormDataType = z.infer<typeof EVENT_FORM_SCHEMA>;
