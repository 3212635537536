import { FaRegCircleUser } from 'react-icons/fa6';
import { FcFolder } from 'react-icons/fc';

import { FolderType, FolderTypeEnum } from 'app/models/Drive';
import { useAuthStore, useShopStore } from 'app/stores';

import Button from '../Button';
import { Container } from '../Page';

interface Props {
  currentFolderType: string | null;
  itemOnClick: (folderType: FolderType) => void;
}

export const DriveLeftBar = ({ currentFolderType, itemOnClick }: Props) => {
  const { profile } = useAuthStore();
  const { shop } = useShopStore();

  return (
    <Container containerClassName="flex-1">
      <div className="p-[16px]">
        {!(profile?.role === '店主' || profile?.role === '店員' || shop?.fromSV === 'partner') ? (
          <Button
            variant={currentFolderType === 'others' ? 'contained' : 'text'}
            color="primary"
            containerStyle="flex items-center p-[8px] gap-[4px] w-full"
            onClick={() => { itemOnClick('others'); }}
          >
            <FcFolder className="size-[18px]" />
            {FolderTypeEnum.others}
          </Button>
        ) : null}
        <Button
          variant={currentFolderType === 'private' ? 'contained' : 'text'}
          color="primary"
          containerStyle="flex items-center p-[8px] gap-[4px] w-full"
          onClick={() => { itemOnClick('private'); }}
        >
          <FaRegCircleUser className="size-[18px]" />
          {FolderTypeEnum.private}
        </Button>
      </div>
    </Container>
  );
};
