const COLORS = {
  brand: {
    primary: '#4361ee',
    secondary: '#FFA021',
    attention: '#F04C4D',
    link: '#0F18EF',
  },
  text: {
    primary: '#343a40',
    secondary: '#6c757d',
    disabled: '#B2AEB5',
    light: '#adb5bd',
    grey: '#ced4da',
  },
  bg: {
    primary: '#f3f4f7',
    secondary: '#FAFAFA',
    modal: '#0000001A',
    disabled: '#F3F3F3',
    'modal-overlay': '#1D1D1DCC',
    countdown: '#FEFBD3',
    row: '#f6f8fc',
    'option-card': '#50A5F10D',
  },
  border: {
    brand: '#EDEDED',
    stroke: '#DEDEDE',
    field: '#E5E7EB',
  },
  card: {
  },
};
export default COLORS;
