import { Filter, ListResult } from 'app/models/Common';
import { DepartmentResponse, DepartmentType } from 'app/models/Department';

import Axios from '.';

/*
* List all the department (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/department)
*/

export interface GetAllDepartmentParams {
  departmentType?: DepartmentType;
  belongsDepartmentId?: number;
  search?: string;
}

export const getAllDepartment = async (params: GetAllDepartmentParams) => {
  const { data } = await Axios.get<DepartmentResponse[]>('/commerces/department', { params });
  return data;
};

/*
* Get department by departmentId (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/department/-departmentId-)
*/

export const getOneDepartment = async (departmentId: number) => {
  const { data } = await Axios.get<DepartmentResponse>(`/commerces/department/${departmentId}`);
  return data;
};

/*
* Create new department (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/department)
*/

interface CreateDepartmentGroupParams {
  departmentType: 'group';
  name: string;
  shopId?: number | null;
}

interface CreateDepartmentFamilyParams {
  departmentType: 'family';
  name: string;
  shopId: number | null;
  belongsDepartmentId: number;
}

interface CreateDepartmentDistrictParams {
  departmentType: 'district';
  name: string;
  title: string | null;
  shopId: number | null;
  districtCode: string | null;
  belongsDepartmentId: number;
}

export interface CreateDepartmentParams {
  Department: CreateDepartmentGroupParams | CreateDepartmentFamilyParams | CreateDepartmentDistrictParams;
}

export const createDepartment = async (params: CreateDepartmentParams) => {
  const { data } = await Axios.post<DepartmentResponse>('/commerces/department', params);
  return data;
};

/*
* Delete department by departmentId (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#delete-/v2/commerces/department/-departmentId-)
*/

export const deleteDepartment = async (departmentId: number) => {
  await Axios.delete(`/commerces/department/${departmentId}`);
};

/*
* Update department by departmentId (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#patch-/v2/commerces/department/-departmentId-)
*/

interface UpdateDepartmentParams {
  name?: string
  districtCode?: string | null
  title?: string | null
  shopId?: number | null
  belongsDepartmentId?: number | null
}

export const updateDepartment = async (departmentId: number, params: UpdateDepartmentParams) => {
  const { data } = await Axios.patch<DepartmentResponse>(`/commerces/department/${departmentId}`, params);
  return data;
};

/*
* List All Departments in List Response, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#patch-/v2/commerces/department/-departmentId-)
*/

export interface GetAllDepartmentInListParams extends Filter<DepartmentResponse> {
  search?: string;
  name?: string
  belongsDepartmentId?: number;
  departmentType?: DepartmentType;
  hasShop?: boolean;
}

export const getAllDepartmentInList = async (params: GetAllDepartmentInListParams) => {
  const { data } = await Axios.get<ListResult<DepartmentResponse>>('/commerces/department/list', { params });
  return data;
};
