export interface TimeStamp {
  createdAt: string;
  deletedAt?: null | string;
  updatedAt: string;
}

export interface OptionItem {
  value: string | number;
  label: string;
}

export type FormType = 'new' | 'edit' | 'copy' | 'view';

export interface ListResult<T> {
  count: number;
  hasNext: boolean;
  limit: number;
  offset: number;
  page?: number;
  rows: T[];
}

export interface Filter<T> {
  limit: number;
  page?: number;
  offset?: number;
  desc?: (keyof T | string)[];
  asc?: (keyof T | string)[];
}

export type SortingState = 'asc' | 'desc' | 'default';

export interface ErrorResponse {
  body?: { fields: Record<string, string> };
  errors?: Error[];
  message?: string;
  name?: string
  status?: number
}

export interface Error {
  instance: unknown;
  message: string;
  origin: string;
  path: string;
  type: string;
  validatorArgs: string;
  validatorKey: string;
  validatorName: string;
  value: string;
}

export enum FilterTypeOption {
  search = 'search',
  select = 'select',
  date = 'date',
  autocomplete = 'autocomplete',
  sort = 'sort',
  time = 'time',
}

export enum HTMLLink {
  'tel' = '電話',
  'link' = '網址',
  'email' = '電郵',
  'video' = '影片',
}

export type HTMLLinkType = keyof typeof HTMLLink;

export interface FileResponse {
  fileName: string;
  url: string;
  supportSizes: string[];
}

export interface LocalFile extends File {
  preview?: string;
  formattedSize?: string;
}

export interface FormPreviewItem {
  name: string;
  preview?: string;
}

export interface JobStatusResponse {
  jobId: number;
  progress: number;
  status: JobStatusType;
}

export enum JobStatus {
  active = '上載中',
  completed = '已完成',
  failed = '失敗',
}

export type JobStatusType = keyof typeof JobStatus;

export enum PublishStatusEnum {
  Published = '立即發佈',
  Unpublished = '預約發佈',
}
export type PublishStatusType = keyof typeof PublishStatusEnum;

export enum DraftingPublishStatusEnum {
  Published = '已發佈',
  Unpublished = '未發佈',
}
export type DraftingPublishStatusType = keyof typeof DraftingPublishStatusEnum;

export interface HrefQueryObject {
  pathname: string;
  query?: { [key: string]: string | number };
}

export type HrefType = string | HrefQueryObject;
