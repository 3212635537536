import 'flatpickr/dist/themes/material_blue.css';
import 'app/assets/scss/datePicker.scss';

import dayjs from 'dayjs';
import locale from 'flatpickr/dist/l10n/zh-tw';
import _ from 'lodash';
import qs from 'qs';
import { useCallback, useMemo } from 'react';
import Flatpickr from 'react-flatpickr';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';

import cn from 'app/utils/ClassName';

interface Props {
  label: string;
  name: { start: string, end?: string };
  placeholder?: string;
  className?: string;
  showMonths?: number;
  timeInclusive?: boolean;
}

interface TempFilter {
  [x: string]: string | undefined | number;
}

export const DateFilter = ({
  label,
  name,
  placeholder,
  className,
  showMonths = 1,
  timeInclusive = false,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const checkTimeInclusive = (date?: string, type?: 'start' | 'end') => {
    if (!date) {
      return undefined;
    }
    if (timeInclusive) {
      if (type && type === 'start') {
        return dayjs(date).startOf('date').toISOString();
      }
      if (type && type === 'end') {
        return dayjs(date).endOf('date').toISOString();
      }
    }
    return date;
  };

  const dateValue: string | string[] = useMemo(() => {
    const queries = qs.parse(searchParams.toString()) as TempFilter;
    if (queries[name.start] && name.end && queries[name.end]) {
      return [
        (queries[name.start] as string)
          ? (queries[name.start] as string)
          : '',
        (queries[name.end] as string)
          ? (queries[name.end] as string)
          : '',
      ];
    }
    if (queries[name.start]) {
      return (queries[name.start] as string) ? (queries[name.start] as string) : '';
    }
    return '';
  }, [name, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceValueOnChange = useCallback(
    _.debounce((e: string | string[]) => {
      const queries = qs.parse(searchParams.toString()) as TempFilter;
      const tempFilter: TempFilter = Array.isArray(e)
        ? {
          ...queries,
          [name.start]: checkTimeInclusive(e[0], 'start'),
          [name.end || '']: checkTimeInclusive(e[1], 'end'),
          page: 1,
        }
        : {
          ...queries,
          [name.start]: e || undefined,
          ...(name.end ? { [name.end]: undefined } : {}),
          page: 1,
        };
      const newQueries = qs.stringify(tempFilter);
      setSearchParams(newQueries);
    }, 300),
    [searchParams, setSearchParams, checkTimeInclusive],
  );

  return (
    <div
      className={cn(
        'flex flex-col py-2',
        !name.end ? 'min-w-[150px]' : 'min-w-[210px]',
        className,
      )}
    >
      <label className="text-[14px] text-text-secondary">{label}</label>
      <div className="relative w-full">
        <Flatpickr
          className="w-full border-b border-border-brand pr-[22px] text-[14px] outline-none placeholder:text-text-light focus:border-brand-primary focus:outline-0"
          placeholder={placeholder || '日期'}
          options={{
            mode: !name.end ? 'single' : 'range',
            altInput: true,
            altFormat: 'Y-m-d',
            locale: locale.zh_tw,
            showMonths,
          }}
          value={dateValue}
          onChange={(date) => {
            if (name.end && date[0] && date[1]) {
              debounceValueOnChange([
                dayjs(date[0]).format('YYYY-MM-DD'),
                dayjs(date[1]).format('YYYY-MM-DD'),
              ]);
            }
            if (!name.end) {
              debounceValueOnChange(dayjs(date[0]).format('YYYY-MM-DD'));
            }
          }}
        />
        <MdOutlineCalendarMonth className="absolute right-1 top-1 size-4 text-text-light" />
      </div>
    </div>
  );
};
