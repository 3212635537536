import _ from 'lodash';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useSearchParams } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';

import { SELECT_FILTER_STYLE } from 'app/constants';
import { OptionItem } from 'app/models/Common';
import cn from 'app/utils/ClassName';

interface Props {
  label: string;
  name: string;
  options: OptionItem[];
  placeholder?: string;
  className?: string;
  onMenuScrollToBottom?: () => void;
  onInputChange?: (e: string) => void;
}

interface TempFilter {
  [x: string]: string | undefined | number;
}

export const SelectFilter = ({
  label,
  name,
  options,
  placeholder,
  className,
  onMenuScrollToBottom,
  onInputChange,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queries = qs.parse(searchParams.toString()) as TempFilter;
  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<OptionItem | null>(null);

  useEffect(() => {
    const foundOption = options.find(
      (option) => option.value.toString() === queries[name],
    );
    if (!queries[name]) {
      setValue(null);
    }
    if (foundOption) {
      setValue(foundOption);
    }
  }, [options, queries, name]);

  const onValueChange = useCallback(
    (e: SingleValue<OptionItem>) => {
      setValue(e);
      const tempFilter: TempFilter = {
        ...queries,
        [name]: e?.value.toString() || undefined,
        page: 1,
      };
      const newQueries = qs.stringify(tempFilter);
      setSearchParams(newQueries);
    },
    [name, queries, setSearchParams],
  );

  const searchOnChange = (text: string) => {
    setInputValue(text);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    _.debounce(onInputChange ?? (() => {}), 500),
    [],
  );

  useEffect(() => {
    debounceSearch(inputValue);
  }, [inputValue, debounceSearch]);

  return (
    <div className={cn('flex flex-col py-2', className)}>
      <label className="text-[14px] text-text-secondary">{label}</label>
      <div className="relative flex w-full items-center border-b border-border-brand">
        <Select
          className="w-full min-w-[188px] pr-[22px] text-[14px] outline-none"
          value={value}
          isMulti={false}
          placeholder={placeholder}
          onChange={onValueChange}
          inputValue={inputValue}
          noOptionsMessage={() => '沒有選項'}
          options={options}
          menuPosition="absolute"
          styles={SELECT_FILTER_STYLE}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onInputChange={searchOnChange}
        />
        <IoIosArrowDown className="absolute right-1 top-1 size-4 text-text-light" />
      </div>
    </div>
  );
};
