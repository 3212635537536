import { create } from 'zustand';

export type CommerceItemStore = {
  newsSubmittedCount: number;
  productSubmittedCount: number;
  eventSubmittedCount: number;
  courseSubmittedCount: number;
  updateNewsSubmittedCount: (count: number) => void;
  updateProductSubmittedCount: (count: number) => void;
  updateEventSubmittedCount: (count: number) => void;
  updateCourseSubmittedCount: (count: number) => void;
  newsModifyRequestCount: number;
  productModifyRequestCount: number;
  eventModifyRequestCount: number;
  courseModifyRequestCount: number;
  updateNewsModifyRequestCount: (count: number) => void;
  updateProductModifyRequestCount: (count: number) => void;
  updateEventModifyRequestCount: (count: number) => void;
  updateCourseModifyRequestCount: (count: number) => void;
};

export const useCommerceItemStore = create<CommerceItemStore>()(
  (set) => ({
    newsSubmittedCount: 0,
    productSubmittedCount: 0,
    eventSubmittedCount: 0,
    courseSubmittedCount: 0,
    updateNewsSubmittedCount: (count) => set((prev) => ({
      ...prev,
      newsSubmittedCount: count,
    })),
    updateProductSubmittedCount: (count) => set((prev) => ({
      ...prev,
      productSubmittedCount: count,
    })),
    updateEventSubmittedCount: (count) => set((prev) => ({
      ...prev,
      eventSubmittedCount: count,
    })),
    updateCourseSubmittedCount: (count) => set((prev) => ({
      ...prev,
      courseSubmittedCount: count,
    })),
    newsModifyRequestCount: 0,
    productModifyRequestCount: 0,
    eventModifyRequestCount: 0,
    courseModifyRequestCount: 0,
    updateNewsModifyRequestCount: (count) => set((prev) => ({
      ...prev,
      newsModifyRequestCount: count,
    })),
    updateProductModifyRequestCount: (count) => set((prev) => ({
      ...prev,
      productModifyRequestCount: count,
    })),
    updateEventModifyRequestCount: (count) => set((prev) => ({
      ...prev,
      eventModifyRequestCount: count,
    })),
    updateCourseModifyRequestCount: (count) => set((prev) => ({
      ...prev,
      courseModifyRequestCount: count,
    })),
  }),
);
