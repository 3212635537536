import { Outlet } from 'react-router-dom';

import { ROUTES } from 'app/constants';
import { MainLayout } from 'app/containers';
import { AdminFormPage, AdminListPage } from 'app/pages/AdminPage';
import { BannerSortPage, BannerListPage, BannerFormPage } from 'app/pages/BannerPage';
import { CommerceBannerSortPage } from 'app/pages/CommerceBannerPage';
import { CommerceItemCategorySortPage } from 'app/pages/CommerceItemCategoryPage';
import { CompanyActivityFormPage, CompanyActivityListPage } from 'app/pages/CompanyActivityPage';
import {
  CategoryContactListPage, ContactCategorySortPage, ContactListPage, ContactFormPage, ContactSortPage,
} from 'app/pages/ContactPage';
import { CourseFormPage, CourseListPage } from 'app/pages/CoursePage';
import { DepartmentSortPage } from 'app/pages/DepartmentPage';
import { DrivePage } from 'app/pages/DrivePage/LazyLoad';
import { EventFormPage, EventListPage } from 'app/pages/EventPage';
import { FeaturedHashTagSortPage } from 'app/pages/HashTagPage';
import { HomePage } from 'app/pages/HomePage';
import { InfoFormPage, InfoListPage, InfoSortPage } from 'app/pages/InfoPage';
import { InstantEventListPage } from 'app/pages/InstantEventListPage';
import { LogoutPage } from 'app/pages/LogoutPage/LazyLoad';
import { MaintenanceFormPage } from 'app/pages/Maintenance';
import { NewsFormPage, NewsListPage } from 'app/pages/NewsPage';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { NotificationFormPage, NotificationListPage } from 'app/pages/NotificationPage';
import { PerformanceListPage, RankingListPage, PerformanceFormPage } from 'app/pages/PerformancePage';
import { PopUpBannerFormListPage } from 'app/pages/PopUpBannerPage';
import { ProductFormPage, ProductListPage } from 'app/pages/ProductPage';
import {
  ShopFormPage, ShopListPage, ShopCategorySortPage, FeaturedShopSortPage, ShopSortPage,
} from 'app/pages/ShopPage';
import { StatementListPage, StatementFormPage, StatementDetailPage } from 'app/pages/StatementPage';
import { SurveyFormPage, SurveyListPage } from 'app/pages/SurveyPage';
import { SurveyResponseDetailPage, SurveyResponseListPage } from 'app/pages/SurveyResponsePage';
import { SystemConfigFormPage } from 'app/pages/SystemConfigPage';
import { TransactionListPage } from 'app/pages/TransactionPage';
import {
  UserDetailPage, UserFormPage, UserInvitationFormPage, UserListPage, UserTagListPage,
} from 'app/pages/UserPage';

const App = () => (
  <MainLayout>
    <Outlet />
  </MainLayout>
);

export const ProtectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: ROUTES.HOME,
        element: <HomePage />,
      },
      // banner
      {
        path: ROUTES.BANNER_LIST,
        element: <BannerListPage />,
      },
      {
        path: `${ROUTES.BANNER_LIST}/:type`,
        element: <BannerFormPage />,
      },
      {
        path: `${ROUTES.BANNER_LIST}/:type/:id`,
        element: <BannerFormPage />,
      },
      {
        path: ROUTES.BANNER_LIST_SORT,
        element: <BannerSortPage />,
      },
      // pop up banner
      {
        path: ROUTES.POP_UP_BANNER_FORM,
        element: <PopUpBannerFormListPage />,
      },
      // statement
      {
        path: ROUTES.STATEMENTS_LIST(),
        element: <StatementListPage />,
      },
      {
        path: `${ROUTES.STATEMENTS_DETAIL_BASIC}/:id`,
        element: <StatementDetailPage />,
      },
      {
        path: `${ROUTES.STATEMENTS_LIST()}/:type`,
        element: <StatementFormPage />,
      },
      {
        path: `${ROUTES.STATEMENTS_LIST()}/:type/:id`,
        element: <StatementFormPage />,
      },
      // user
      {
        path: `${ROUTES.USERS}`,
        element: <UserListPage />,
      },
      {
        path: `${ROUTES.USERS}/:id/detail`,
        element: <UserDetailPage />,
      },
      {
        path: ROUTES.USER_TAGS_LIST,
        element: <UserTagListPage />,
      },
      {
        path: `${ROUTES.USERS}/:type`,
        element: <UserFormPage />,
      },
      {
        path: `${ROUTES.USERS}/:type/:id`,
        element: <UserFormPage />,
      },
      {
        path: `${ROUTES.USERS_INVITATION}/:type`,
        element: <UserInvitationFormPage />,
      },
      // admin
      {
        path: `${ROUTES.ADMINS}`,
        element: <AdminListPage />,
      },
      {
        path: `${ROUTES.ADMINS}/:type`,
        element: <AdminFormPage />,
      },
      {
        path: `${ROUTES.ADMINS}/:type/:id`,
        element: <AdminFormPage />,
      },
      {
        path: `${ROUTES.ADMINS}/:type/:id/:password`,
        element: <AdminFormPage />,
      },
      // performance
      {
        path: `${ROUTES.PERFORMANCE_LIST()}`,
        element: <PerformanceListPage />,
      },
      {
        path: `${ROUTES.PERFORMANCE_RANKING_LIST}`,
        element: <RankingListPage />,
      },
      {
        path: `${ROUTES.PERFORMANCE_LIST()}/:type`,
        element: <PerformanceFormPage />,
      },
      {
        path: `${ROUTES.PERFORMANCE_LIST()}/:type/:id`,
        element: <PerformanceFormPage />,
      },
      // info
      {
        path: `${ROUTES.INFO_LIST}`,
        element: <InfoListPage />,
      },
      {
        path: `${ROUTES.INFO_LIST}/:type`,
        element: <InfoFormPage />,
      },
      {
        path: `${ROUTES.INFO_LIST}/:type/:id`,
        element: <InfoFormPage />,
      },
      {
        path: `${ROUTES.INFO_LIST_SORT}`,
        element: <InfoSortPage />,
      },
      // contact
      {
        path: `${ROUTES.CONTACT_CATEGORY_SORT}`,
        element: <ContactCategorySortPage />,
      },
      {
        path: `${ROUTES.CONTACT_LIST}`,
        element: <ContactListPage />,
      },
      {
        path: `${ROUTES.CONTACT_SORT_BASE}/:id`,
        element: <ContactSortPage />,
      },
      {
        path: `${ROUTES.CONTACT_CATEGORY_SORT}`,
        element: <ContactCategorySortPage />,
      },
      {
        path: `${ROUTES.CONTACT_LIST}/:type`,
        element: <ContactFormPage />,
      },
      {
        path: `${ROUTES.CONTACT_VIEW_BASE}/:id`,
        element: <CategoryContactListPage />,
      },
      {
        path: `${ROUTES.CONTACT_LIST}/:type/:id`,
        element: <ContactFormPage />,
      },
      {
        path: `${ROUTES.CONTACT_LIST}`,
        element: <ContactListPage />,
      },
      // company activity
      {
        path: `${ROUTES.COMPANY_ACTIVITY_LIST}`,
        element: <CompanyActivityListPage />,
      },
      {
        path: `${ROUTES.COMPANY_ACTIVITY_LIST}/:type`,
        element: <CompanyActivityFormPage />,
      },
      {
        path: `${ROUTES.COMPANY_ACTIVITY_LIST}/:type/:id`,
        element: <CompanyActivityFormPage />,
      },
      // maintenance
      {
        path: `${ROUTES.MAINTENANCE}`,
        element: <MaintenanceFormPage />,
      },
      // notification
      {
        path: ROUTES.NOTIFICATIONS_LIST,
        element: <NotificationListPage />,
      },
      {
        path: `${ROUTES.NOTIFICATIONS_LIST}/:type`,
        element: <NotificationFormPage />,
      },
      // shop
      {
        path: `${ROUTES.SHOP_SORT}`,
        element: <ShopSortPage />,
      },
      {
        path: `${ROUTES.SHOP_CATEGORY_SORT}`,
        element: <ShopCategorySortPage />,
      },
      {
        path: `${ROUTES.SHOP_LIST}`,
        element: <ShopListPage />,
      },
      {
        path: `${ROUTES.SHOP_LIST}/:type`,
        element: <ShopFormPage />,
      },
      {
        path: `${ROUTES.SHOP_LIST}/:type/:id`,
        element: <ShopFormPage />,
      },
      {
        path: `${ROUTES.SHOP_FEATURED_SORT}`,
        element: <FeaturedShopSortPage />,
      },
      // hashtag
      {
        path: `${ROUTES.FEATURED_HASH_TAG_SORT}`,
        element: <FeaturedHashTagSortPage />,
      },
      // commerce item
      {
        path: `${ROUTES.COMMERCE_ITEM_CATEGORY_SORT}`,
        element: <CommerceItemCategorySortPage />,
      },
      // transaction
      {
        path: ROUTES.TRANSACTION_LIST(),
        element: <TransactionListPage />,
      },
      // survey
      {
        path: ROUTES.SURVEY_LIST,
        element: <SurveyListPage />,
      },
      {
        path: `${ROUTES.SURVEY_LIST}/:type`,
        element: <SurveyFormPage />,
      },
      {
        path: `${ROUTES.SURVEY_LIST}/:type/:id`,
        element: <SurveyFormPage />,
      },
      // survey response
      {
        path: ROUTES.SURVEY_RESPONSE_LIST(),
        element: <SurveyResponseListPage />,
      },
      {
        path: `${ROUTES.SURVEY_RESPONSE_LIST()}/:commerceApplicationId`,
        element: <SurveyResponseListPage />,
      },
      {
        path: `${ROUTES.SURVEY_RESPONSE_LIST()}/view/:id`,
        element: <SurveyResponseDetailPage />,
      },
      // department
      {
        path: `${ROUTES.DEPARTMENT_SORT}`,
        element: <DepartmentSortPage />,
      },
      // event
      {
        path: ROUTES.EVENTS_LIST,
        element: <EventListPage />,
      },
      {
        path: `${ROUTES.EVENTS_LIST}/:type`,
        element: <EventFormPage />,
      },
      {
        path: `${ROUTES.EVENTS_LIST}/:type/:id`,
        element: <EventFormPage />,
      },
      // news
      {
        path: ROUTES.NEWS_LIST,
        element: <NewsListPage />,
      },
      {
        path: `${ROUTES.NEWS_LIST}/:type`,
        element: <NewsFormPage />,
      },
      {
        path: `${ROUTES.NEWS_LIST}/:type/:id`,
        element: <NewsFormPage />,
      },
      // product
      {
        path: ROUTES.PRODUCT_LIST,
        element: <ProductListPage />,
      },
      {
        path: `${ROUTES.PRODUCT_LIST}/:type`,
        element: <ProductFormPage />,
      },
      {
        path: `${ROUTES.PRODUCT_LIST}/:type/:id`,
        element: <ProductFormPage />,
      },
      // drive
      {
        path: ROUTES.DRIVE,
        element: <DrivePage />,
      },
      // course
      {
        path: ROUTES.COURSE_LIST,
        element: <CourseListPage />,
      },
      {
        path: `${ROUTES.COURSE_LIST}/:type`,
        element: <CourseFormPage />,
      },
      {
        path: `${ROUTES.COURSE_LIST}/:type/:id`,
        element: <CourseFormPage />,
      },
      // commerce banner
      {
        path: ROUTES.COMMERCE_BANNER_SORT,
        element: <CommerceBannerSortPage />,
      },
      // system config
      {
        path: ROUTES.SYSTEM_CONFIG,
        element: <SystemConfigFormPage />,
      },
      // instant event
      {
        path: ROUTES.INSTANT_EVENT_LIST,
        element: <InstantEventListPage />,
      },
      {
        path: ROUTES.LOGIN,
        element: <HomePage />,
      },
      { path: '/logout', element: <LogoutPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
];
