import * as Sentry from '@sentry/react';
import { Component, ReactNode } from 'react';

import { CURRENT_ENV, VERSION_NUMBER } from 'app/configs';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(
      new Error(
        `[UI issue]
          [V-${CURRENT_ENV}-${VERSION_NUMBER}]
          ${error}
        `,
      ),
    );
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="flex w-full flex-col items-center justify-center gap-[8px]">
          <h1 className="font-bold">Something went wrong.</h1>
          <p>
            An error has occurred. Our team has been notified. Please try again later.
          </p>
          <a href="/" className="rounded-md bg-brand-link px-[12px] py-[4px] text-white shadow-md hover:brightness-125">Home</a>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
