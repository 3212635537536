import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { Tooltip } from 'react-tooltip';

import { TableCheckbox, SortingButton } from 'app/components';
import Button from 'app/components/Button';
import { InstantEventResponse } from 'app/models/InstantEvent';

export const InstantEventListColumns = (): ColumnDef<InstantEventResponse>[] => (
  [
    {
      header: ({ table }) => (
        <div className="flex flex-nowrap items-center">
          <TableCheckbox
            checked={table.getIsAllRowsSelected()}
            id="name"
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
          <label htmlFor="name" className="grow pl-[20px]">
            標題
          </label>
        </div>
      ),
      accessorKey: 'name',
      id: 'name',
      cell: (info) => (
        <div className="flex flex-nowrap items-center">
          <TableCheckbox
            checked={info.row.getIsSelected()}
            disabled={!info.row.getCanSelect()}
            onChange={info.row.getToggleSelectedHandler()}
            id={`name-${info.row.index as number}`}
          />
          <label htmlFor={`name-${info.row.index}`} className="grow pl-[20px]">
            {info.row.original.name}
          </label>

        </div>
      ),
    },
    {
      header: '開始日期',
      accessorKey: 'startDate',
      id: 'startDate',
      size: 150,
      cell: (info) => (
        <div className="flex flex-nowrap items-center whitespace-pre-line">
          {info.row.original.startDate ? dayjs(info.row.original.startDate).format('YYYY-MM-DD') : '-'}
        </div>
      ),
    },
    {
      header: '結束日期',
      accessorKey: 'endDate',
      id: 'endDate',
      size: 150,
      cell: (info) => (
        <div className="flex flex-nowrap items-center whitespace-pre-line">
          {info.row.original.endDate ? dayjs(info.row.original.endDate).format('YYYY-MM-DD') : '-'}
        </div>
      ),
    },
    {
      header: '參加人數',
      accessorKey: 'checkedInCount',
      id: 'checkedInCount',
      size: 100,
      cell: (info) => (
        <div className="flex flex-nowrap items-center whitespace-pre-line">
          {info.row.original.checkedInCount || 0}
        </div>
      ),
    },
    {
      header: () => (
        <SortingButton name="createdAt" label="建立日期" />
      ),
      footer: (props) => props.column.id,
      accessorFn: (row) => row.createdAt,
      id: 'createdAt',
      cell: (info) => (
        <div className="flex flex-nowrap items-center">
          {info.getValue() ? dayjs(info.getValue() as string).format('YYYY-MM-DD') : '-'}
        </div>
      ),
    },
    {
      header: '',
      footer: (props) => props.column.id,
      accessorFn: (row) => row.instantEventId,
      id: 'instantEventId',
      cell: (info) => (
        <div className="flex flex-nowrap items-center justify-end">
          <Button
            variant="text"
            color="primary"
            onClick={() => {}}
            containerStyle="p-2 hover:text-brand-primary"
          >
            <RiFileExcel2Fill
              className="size-5 text-[#1D6F42]"
              data-tooltip-id={`export-instant-event-${info.getValue()}`}
            />
          </Button>
          <Tooltip
            id={`export-instant-event-${info.getValue()}`}
            place="top"
            content="匯出即時活動"
          />
        </div>
      ),
    },
  ]
);
