import dayjs from 'dayjs';
import { TbCoinFilled } from 'react-icons/tb';
import { z } from 'zod';

import { CreateCommerceItemProps } from 'app/apis/CommerceServices';
import { getAllDepartmentInList } from 'app/apis/DepartmentServices';
import {
  CommerceItemCommonFormDataType,
  INITIALIZED_COMMERCE_ITEM_NOTIFICATION_INFO,
  CommerceItemPermissionFormDataType,
  INITIALIZED_COMMERCE_ITEM_PERMISSION,
} from 'app/constants/CommerceItem';
import { AdminRoleType } from 'app/models/Admin';
import { CommerceItemResponse, CommercePermissionResponse, PaymentOptionType } from 'app/models/CommerceItem';
import { FormType } from 'app/models/Common';
import { DepartmentResponse } from 'app/models/Department';
import { formatNumber, roundNumber, toClientPrice } from 'app/utils';

export const checkPaymentType = (
  cash: number | undefined,
  point: number | undefined,
): PaymentOptionType => {
  if (cash && point) {
    return 'cashAndPoint';
  }
  if (cash) {
    return 'cash';
  }
  if (point) {
    return 'point';
  }
  return 'free';
};

export const transformToCommonCommerceItemFormData = ({ CommercePermissions, ScheduledNotification }: Pick<CommerceItemResponse, 'CommercePermissions' | 'ScheduledNotification'>, type?: FormType): CommerceItemCommonFormDataType => ({
  permission: {
    CommercePermissions: CommercePermissions.map(({
      commercePermissionId,
      userType,
      memberLevel,
      userTag,
      branchName,
      family,
      jobTitle,
    }) => ({
      commercePermissionId: type === 'edit' || type === 'view' ? commercePermissionId : undefined,
      userType: userType || null,
      memberLevel: memberLevel || null,
      userTag: userTag || null,
      branchName: branchName || null,
      family: family || null,
      jobTitle: jobTitle || null,
    })),
    displayPermission: 'onlySpecifiedUserCanView',
  },
  pushNotification: {
    ...INITIALIZED_COMMERCE_ITEM_NOTIFICATION_INFO,
    publishType: ScheduledNotification?.publishType ?? 'instant',
    scheduledTime: ScheduledNotification?.scheduledTime
      ? dayjs(ScheduledNotification?.scheduledTime).toDate()
      : null,
    title: ScheduledNotification?.title || '',
    content: ScheduledNotification?.content || '',
    scheduledNotificationId: type === 'edit' ? ScheduledNotification?.scheduledNotificationId : undefined,
  },
});

export const transformToCommonCommerceItemParams = ({
  permission: {
    CommercePermissions,
  },
  pushNotification: {
    publishType,
    scheduledTime,
    title: notificationTitle,
    content,
    scheduledNotificationId,
  },
}: CommerceItemCommonFormDataType, type?: FormType): Pick<CreateCommerceItemProps, 'CommercePermissions' | 'ScheduledNotification'> => ({
  CommercePermissions: CommercePermissions.filter((permission) => !Object.values(permission).every((x) => !x)).map(({
    userType, memberLevel, userTag, branchName, family, jobTitle, commercePermissionId,
  }) => ({
    commercePermissionId: type === 'edit' || type === 'view' ? commercePermissionId : undefined,
    userType: userType || null,
    memberLevel: memberLevel || null,
    userTag: userTag || null,
    branchName: branchName || null,
    family: family || null,
    jobTitle: jobTitle || null,
  })),
  ScheduledNotification: notificationTitle ? {
    publishType,
    scheduledTime: dayjs(scheduledTime || undefined).toISOString(),
    title: notificationTitle || undefined,
    content: content || undefined,
    scheduledNotificationId,
  } : undefined,
});

export const validatePermission = (permission: CommerceItemPermissionFormDataType, ctx: z.RefinementCtx) => {
  if (!permission.CommercePermissions
    .map(({ commercePermissionId, ...permissionRest }) => ({ ...permissionRest }))
    .some(
      (permissionItem) => Object.values(permissionItem).some((val) => val),
    )) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: '最少選擇一項',
      path: ['permission', 'CommercePermissions', ''],
    });
  }
};

export const calculationPayingTotal = (cash?: number, point?: number, count = 1) => {
  if (cash && point) {
    return (
      <div className="flex flex-nowrap items-center gap-[4px]">
        $
        {formatNumber(roundNumber(toClientPrice(cash) * count))}
        {' '}
        +
        <TbCoinFilled fill="#FFA021" className="size-[16px]" />
        {formatNumber(point * count)}
      </div>
    );
  } if (point) {
    return (
      <div className="flex flex-nowrap items-center gap-[4px]">
        <TbCoinFilled fill="#FFA021" className="size-[16px]" />
        {formatNumber(point * count)}
      </div>
    );
  }
  return (
    <div className="flex flex-nowrap items-center">
      $
      {formatNumber(roundNumber(toClientPrice(cash || 0) * count))}
    </div>
  );
};

interface CalculateAmountProps { cash?: number, point?: number, numberOfOption?: number | string }

export const calculationAmount = (items: CalculateAmountProps[]) => {
  const { totalCash, totalPoint } = items.reduce<{ totalCash: number, totalPoint: number }>((prev, curr) => ({
    totalCash: prev.totalCash + ((curr.cash || 0) * (curr.numberOfOption ? Number(curr.numberOfOption) : 0)),
    totalPoint: prev.totalPoint + ((curr.point || 0) * (curr.numberOfOption ? Number(curr.numberOfOption) : 0)),
  }), { totalCash: 0, totalPoint: 0 });

  if (totalCash && totalPoint) {
    return (
      <div className="flex flex-nowrap items-center gap-[4px]">
        $
        {formatNumber(roundNumber(toClientPrice(totalCash)))}
        {' '}
        +
        <TbCoinFilled fill="#FFA021" className="size-[16px]" />
        {formatNumber(totalPoint)}
      </div>
    );
  } if (totalPoint) {
    return (
      <div className="flex flex-nowrap items-center gap-[4px]">
        <TbCoinFilled fill="#FFA021" className="size-[16px]" />
        {formatNumber(totalPoint)}
      </div>
    );
  }
  return (
    <div className="flex flex-nowrap items-center">
      $
      {formatNumber(roundNumber(toClientPrice(totalCash)))}
    </div>
  );
};

export const getDefaultPermissionValue = (district: string | null, family: string | null, role?: AdminRoleType): CommerceItemPermissionFormDataType['CommercePermissions'][number] => {
  if (role === '超級管理員') {
    return {
      ...INITIALIZED_COMMERCE_ITEM_PERMISSION,
      userType: '訪客',
    };
  }
  return {
    ...INITIALIZED_COMMERCE_ITEM_PERMISSION,
    userType: '會員',
    branchName: district,
    family,
  };
};

export const fetchDefaultPermission = async (role?: AdminRoleType, department?: DepartmentResponse) => {
  let permission: CommercePermissionResponse | null = null;
  if (role === '管理員' && department?.departmentType) {
    const relatedDepartment = await getAllDepartmentInList({
      limit: 1,
      offset: 0,
      departmentType: department?.departmentType,
    });
    if (department?.departmentType === 'district') {
      permission = {
        ...INITIALIZED_COMMERCE_ITEM_PERMISSION,
        userType: '會員',
        branchName: relatedDepartment.rows[0]?.name,
        createdAt: '',
        updatedAt: '',
      };
    }
    if (department?.departmentType === 'family' || department?.departmentType === 'group') {
      permission = {
        ...INITIALIZED_COMMERCE_ITEM_PERMISSION,
        userType: '會員',
        family: relatedDepartment.rows[0]?.name,
        createdAt: '',
        updatedAt: '',
      };
    }
  }
  return permission;
};
