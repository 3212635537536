import qs from 'qs';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { useSearchParams } from 'react-router-dom';

import cn from 'app/utils/ClassName';

interface Props {
  name: string;
  label: string;
  className?: string;
}

interface SortFilter {
  desc?: string;
  asc?: string;
  [x: string]: string | undefined | number;
}

export const SortingButton = ({ name, label, className }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const asc = searchParams.get('asc');
  const desc = searchParams.get('desc');

  const sortOnClick = () => {
    const queries = qs.parse(searchParams.toString());
    const tempFilter: SortFilter = {
      ...queries,
      page: 1,
    };
    if (queries.asc !== name && queries.desc !== name) {
      tempFilter.asc = name;
      tempFilter.desc = undefined;
    } else if (queries.asc === name && queries.desc !== name) {
      tempFilter.asc = undefined;
      tempFilter.desc = name;
    } else if (queries.asc !== name && queries.desc === name) {
      tempFilter.asc = undefined;
      tempFilter.desc = undefined;
    }
    const newQueries = qs.stringify(tempFilter);
    setSearchParams(newQueries);
  };

  return (
    <button
      type="button"
      className={cn('flex flex-nowrap items-center', className)}
      onClick={sortOnClick}
    >
      {label}
      <div className="ml-1 flex flex-col items-center">
        <TiArrowSortedUp
          className={cn(
            'size-3',
            asc === name ? 'text-brand-primary' : 'text-text-light',
          )}
        />
        <TiArrowSortedDown
          className={cn(
            'size-3',
            desc === name ? 'text-brand-primary' : 'text-text-light',
          )}
        />
      </div>
    </button>
  );
};
