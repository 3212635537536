import { ChangeEvent, useEffect, useRef } from 'react';

import cn from 'app/utils/ClassName';

interface TableCheckboxProps {
  checked: boolean;
  className?: string;
  id?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const TableCheckbox = ({
  checked,
  disabled,
  className,
  id,
  indeterminate = false,
  onChange,
}: TableCheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className="min-w-[16px]">
      <input
        ref={checkboxRef}
        checked={checked}
        className={cn(
          'm-0 size-[16px] rounded border-0 accent-brand-primary cursor-pointer',
          className,
        )}
        id={id}
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
      />
    </div>
  );
};
