import dayjs from 'dayjs';
import qs from 'qs';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FilterTypeOption, OptionItem } from 'app/models/Common';
import cn from 'app/utils/ClassName';

import { Badge } from '../Badge';
import {
  DateFilter, SearchFilter, SelectFilter,
} from '../Filters';

interface Props {
  filterItems: FilterItems[];
}

interface SearchFilterType {
  type: FilterTypeOption.search;
  label: string;
  name: string;
  placeholder?: string;
  className?: string;
}

interface SelectFilterType {
  type: FilterTypeOption.select;
  label: string;
  name: string;
  placeholder?: string;
  options: OptionItem[];
  optionMap?: Record<string, string>;
  className?: string;
  unRemovable?: true;
}

interface SortFilterType {
  type: FilterTypeOption.sort;
  label: string;
  name: string;
  optionMap: Record<string, string>;
  className?: string;
  unRemovable?: true;
}

interface DateFilterType {
  type: FilterTypeOption.date;
  label: string;
  name: { start: string; end?: string };
  placeholder?: string;
  className?: string;
  showMonths?: number;
  timeInclusive?: boolean;
}

interface AutoCompleteFilterType {
  type: FilterTypeOption.autocomplete;
  label: string;
  name: string;
  placeholder?: string;
  options: OptionItem[];
  optionMap?: Record<string, string>;
  onMenuScrollToBottom: () => void;
  onInputChange: (e: string) => void;
  className?: string;
}

interface TimeFilterType {
  type: FilterTypeOption.time;
  label: string;
  name: string;
  mode?: 'single' | 'range';
  placeholder?: string;
  className?: string;
}

export type FilterItems =
  | SearchFilterType
  | SelectFilterType
  | SortFilterType
  | DateFilterType
  | AutoCompleteFilterType
  | TimeFilterType;

export const TableFilterRow = ({ filterItems }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterOnRemove = (key: string, key2?: string) => {
    const queries = qs.parse(searchParams.toString());
    if (key in queries) {
      delete queries[key];
    }
    if (key2 && key2 in queries) {
      delete queries[key2];
    }
    if (`${key}From` in queries && `${key}To` in queries) {
      delete queries[`${key}From`];
      delete queries[`${key}To`];
    }
    const newQueries = qs.stringify(queries);
    setSearchParams(newQueries);
  };

  const queries = qs.parse(searchParams.toString());

  const filters = useMemo(
    () => filterItems.map((item) => {
      switch (item.type) {
        case FilterTypeOption.search:
          return (
            <SearchFilter
              key={item.name}
              label={item.label}
              name={item.name}
              placeholder={item.placeholder}
              className={item.className}
            />
          );
        case FilterTypeOption.select:
          return (
            <SelectFilter
              key={item.name}
              label={item.label}
              name={item.name}
              placeholder={item.placeholder}
              options={item.options}
              className={item.className}
            />
          );
        case FilterTypeOption.date:
          return (
            <DateFilter
              key={item.name.start}
              label={item.label}
              name={item.name}
              placeholder={item.placeholder}
              className={item.className}
              showMonths={item.showMonths}
              timeInclusive={item.timeInclusive}
            />
          );
        case FilterTypeOption.autocomplete:
          return (
            <SelectFilter
              key={item.name}
              label={item.label}
              name={item.name}
              placeholder={item.placeholder}
              options={item.options}
              className={item.className}
              onMenuScrollToBottom={item.onMenuScrollToBottom}
              onInputChange={item.onInputChange}
            />
          );
        default:
          return null;
      }
    }),
    [filterItems],
  );

  const filterMap = useMemo(
    () => filterItems.reduce(
      (acc, item) => {
        if (item.type === FilterTypeOption.date) {
          if (item.name.start in queries) {
            acc[item.name.start] = item;
          }
          return acc;
        }
        if (item.name in queries) {
          if (
            item.type === FilterTypeOption.select
              || item.type === FilterTypeOption.autocomplete
          ) {
            const keyValuePair = Object.fromEntries(
              item.options.map((option) => [
                option.value.toString(),
                option.label,
              ]),
            );
            acc[item.name] = { ...item, optionMap: keyValuePair };
          } else {
            acc[item.name] = item;
          }
        }
        // if (item.type === FilterTypeOption.date && item.mode === 'range') {
        //   if ((item.nameForRangeMode?.start || `${item.name}From`) in queries
        //    && (item.nameForRangeMode?.end || `${item.name}To`) in queries) {
        //     acc[item.name] = item;
        //   }
        // }
        if (item.type === FilterTypeOption.time && item.mode === 'range') {
          if (`${item.name}From` in queries && `${item.name}To` in queries) {
            acc[item.name] = item;
          }
        }
        return acc;
      },
      {} as Record<string, FilterItems>,
    ),
    [filterItems, queries],
  );

  const renderValue = (key: string) => {
    const item = filterMap[key];
    switch (item.type) {
      case FilterTypeOption.sort:
        return `${filterMap[key].label}: ${
          item.optionMap[queries[key] as string]
        }`;
      case FilterTypeOption.select:
        return `${filterMap[key].label}: ${item?.optionMap?.[
          queries[key] as string
        ]}`;
      case FilterTypeOption.autocomplete:
        return `${filterMap[key].label}: ${
          item?.optionMap?.[queries[key] as string] || ''
        }`;
      case FilterTypeOption.date:
        if (
          item.name.start in queries
          && item.name.end
          && item.name.end in queries
        ) {
          const from = dayjs(queries[(item.name.start)]
            ?.toString())
            .format(
              'YYYY-MM-DD',
            );
          const to = dayjs(queries[(item.name.end)]
            ?.toString())
            .format(
              'YYYY-MM-DD',
            );

          return `${filterMap[key].label}: ${from} 至 ${to}`;
        }
        return `${filterMap[key].label}: ${queries[key] as string}`;
      case FilterTypeOption.time:
        if (
          item.mode === 'range'
          && `${key}From` in queries
          && `${key}To` in queries
        ) {
          return `${filterMap[key].label}: ${queries[`${key}From`]} 至 ${
            queries[`${key}To`]
          }`;
        }
        return `${filterMap[key].label}: ${queries[key] as string}`;
      default:
        return `${filterMap[key].label}: ${queries[key] as string}`;
    }
  };

  const shouldBorderHidden = useMemo(() => {
    if (filterItems.length === 2
      && filterItems.every((val) => val.type === FilterTypeOption.sort)
      && Object.keys(filterMap).length === 0) {
      return true;
    }
    return false;
  }, [filterItems, filterMap]);

  return (
    <div className={cn('px-4 overflow-visible', shouldBorderHidden ? '' : 'border-b')} id="table-filter-bar">
      <div className="hidden flex-wrap items-end justify-start gap-2 non-mobile:flex">
        {filters}
      </div>
      {Object.keys(filterMap).length > 0 && (
        <div className="flex w-full items-center justify-start gap-[4px] py-2">
          <div className="min-w-max text-[14px] font-light text-text-primary">
            篩選條件：
          </div>
          <div className="flex items-center gap-[4px] overflow-y-auto">
            {Object.entries(filterMap).map(([key, value], index) => (
              <Badge
                variant="contain"
                rounded="full"
                color="grey"
                onClose={!('unRemovable' in value) ? () => filterOnRemove(
                  key,
                  value.type === FilterTypeOption.date ? value.name.end : undefined,
                ) : undefined}
                key={index}
              >
                {renderValue(key)}
              </Badge>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
