import { AdminResponse } from './Admin';
import { TimeStamp } from './Common';

export interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface UserTag {
  userTagId?: number
  userId?: number | null
  tagName: string
  userCount: number
}

export interface UserResponse extends TimeStamp {
  userId: number;
  displayedMemberId: number;
  uid: string;
  email?: string | null;
  contactEmail?: string | null;
  displayName?: string | null;
  chineseName?: string | null;
  isReceivedInformation?: boolean;
  firstName?: string | null;
  lastName?: string | null;
  englishName?: string | null;
  avatar?: string | null;
  notificationToken?: string;
  onceId?: string;
  qrcode?: string | null;
  status: UserStatusType;
  membershipPoint: number;
  agentLicenseNumber?: string | null;
  userType: UserType;
  countryCallingCode: string;
  contactPhone?: string | null;
  marriageStatus?: MarriageStatusType | null;
  memberLevel: MemberLevelType | null;
  jobTitle?: string | null;
  quote: string;
  rankCode?: RankCodeType | null;
  rankCodeNumber?: string | null;
  isStaff: boolean;
  departmentId?: number | null;
  branchCode?: string | null;
  branchName?: string | null;
  entryDate?: string | null;
  birthDate?: string | null;
  family?: string | null;
  firstLoginTime?: string | null;
  cardAddress?: string | null;
  shopId?: number | null;
  adminId?: number | null;
  Admin?: AdminResponse;
  UserAuths: UserAuth[];
  UserTags: UserTag[];
}

export enum UserStatus {
  已停用 = '已停用',
  已啟用 = '已啟用',
}

export type UserStatusType = keyof typeof UserStatus;

export enum UserTypeEnum {
  公眾 = '公眾',
  會員 = '會員',
}

export type UserType = keyof typeof UserTypeEnum;

export enum MarriageStatusEnum {
  已婚 = '已婚',
  未婚 = '未婚',
}

export type MarriageStatusType = keyof typeof MarriageStatusEnum;

export enum MemberLevelEnum {
  普通會員 = '普通會員',
  純銀 = '純銀',
  紅寶石 = '紅寶石',
  黃鑽 = '黃鑽',
  鑽石 = '鑽石',
  綠寶石 = '綠寶石',
  藍寶石 = '藍寶石',
}

export type MemberLevelType = keyof typeof MemberLevelEnum;

export enum RankCodeEnum {
  SERD = '高級行政區域總監',
  ERD = '行政區域總監',
  SRD = '高級區域總監',
  RD = '區域總監',
  SDD = '高級分區總監',
  DD = '分區總監',
  SBM = '高級分行經理',
  BM = '分行經理',
  SUM = '高級分組經理',
  UM = '分組經理',
}

export type RankCodeType = keyof typeof RankCodeEnum;

export type UserJobTitleType =
| '高級行政區域總監'
| '行政區域總監'
| '高級區域總監'
| '區域總監'
| '高級分區總監'
| '分區總監'
| '高級分行經理'
| '分行經理'
| '高級分組經理'
| '分組經理';

export interface UserAuth {
  userAuthId: number;
  userId: number;
  provider?: string
  agentLicenseNumber?: string | null
  biometricsPublicKey?: string | null
  email?: string | null
}

export const USER_LIST_SORT_OPTION_MAP = {
  displayName: '暱稱',
  membershipPoint: '積分',
  createdAt: '建立日期',
  firstLoginTime: '首次登入時間',
  family: '會員家庭',
  branchCode: '區域編號',
  agentLicenseNumber: '營業員編號',
  userType: '會員種類',
  memberLevel: '會籍等級',
  jobTitle: '職位',
};

export interface UserMemberPointRecordResponse extends TimeStamp {
  membershipPointId: number;
  point: number;
  userId: number;
  referrerUserId?: number | null
  refereeUserId?: number | null
  commerceItemId?: number | null
  commerceApplicationId?: number | null
  statementId: number | null;
  adminId: number | null;
  remarks: string | null;
  Admin?: AdminResponse;
  ReferrerUser?: User;
  RefereeUser?: User;
}

export interface UserPreAgentResponse extends TimeStamp {
  preAgentId: number;
  userId: number;
  firstName: string;
  hkId: string;
  lastName: string;
  code: string;
  email: string;
  studentNumber: string;
  User: UserResponse;
}

export interface UserSessionResponse {
  userAgent: string
  deviceName: string
  ip: string
  userId: number
  adminId: number
  roles: string[]
  expiredAt: string
  sessionKey: string
}

export interface UserInvitationResponse {
  invitationToken: string
  invitationLink: string
}
