import { Popover, Transition } from '@headlessui/react';
import {
  Fragment, MouseEventHandler, useCallback, useState,
} from 'react';
import { BiShare, BiTrash } from 'react-icons/bi';
import { FaRegEye } from 'react-icons/fa6';
import { FcImageFile, FcVideoCall, FcFile } from 'react-icons/fc';
import { FiEdit } from 'react-icons/fi';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { IoIosLink } from 'react-icons/io';
import { MdContentCopy, MdOutlineDownload, MdOutlineDriveFileMove } from 'react-icons/md';
import { toast } from 'react-toastify';

import { getDriveFileURL, moveRenameDocument } from 'app/apis/DriveServices';
import {
  copyImageLink,
  copyVideoLink,
  fileOnCopy,
  fileOnDelete,
  fileOnRename,
  getDriveFilePreviewLink,
  isImageFile,
  isVideoFile,
  shareOnPress,
} from 'app/helpers/Drive';
import { DriveFileResponse } from 'app/models/Drive';
import cn from 'app/utils/ClassName';

import Button from '../Button';
import { Image } from '../Image';
import { Spinner } from '../Spinner';

import { DriveFolderFormModal } from './DriveFolderFormModal';
import { DriveMoveToModal } from './DriveMoveToModal';

interface Props {
  data: DriveFileResponse;
  isListView: boolean;
  isSelected: boolean;
  shopId?: number | string;
  itemOnClick: MouseEventHandler<HTMLButtonElement>;
  onPreview: () => void;
  getDriveData: () => Promise<void>;
}

export const DriveFileItem = ({
  data,
  isListView,
  isSelected,
  shopId,
  itemOnClick,
  onPreview,
  getDriveData,
}: Props) => {
  const [renameModalVisible, setRenameModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [moveToModalVisible, setMoveToModalVisible] = useState<boolean>(false);
  const [moveToModalLoading, setMoveToModalLoading] = useState<boolean>(false);
  const [copyLoading, setCopyLoading] = useState<boolean>(false);

  const {
    dir,
    fileName,
    folderType,
    driveType,
  } = data;

  let fileType: 'image' | 'video' | 'other' = 'image';
  let icon = null;
  if (isImageFile(fileName)) {
    fileType = 'image';
    icon = <FcImageFile className="size-[18px]" />;
  } else if (isVideoFile(fileName)) {
    fileType = 'video';
    icon = <FcVideoCall className="size-[18px]" />;
  } else {
    fileType = 'other';
    icon = <FcFile className="size-[18px]" />;
  }

  const coverIcon = useCallback(
    () => getDriveFileURL({
      folderType,
      driveType: shopId ? 'shop' : 'public',
      shopId,
      path: dir === ''
        ? `.${fileName}.jpg`
        : `${dir}/.${fileName}.jpg`,
    }),
    [dir, fileName, folderType, shopId],
  );

  return (
    <button
      className={cn(
        'flex items-center gap-[4px] border',
        isListView ? 'p-[10px]' : 'flex-col rounded-tr-[10px]',
        isListView && !isSelected && 'border-transparent border-b-border-brand',
        isSelected && 'bg-brand-secondary/30',
      )}
      type="button"
      onClick={itemOnClick}
    >
      {isListView
        ? icon
        : (
          <div className="flex w-full items-center justify-center rounded-tr-[10px] border-b bg-white">
            <Image
              src={coverIcon()}
              className="h-[150px] w-full object-cover"
            />
          </div>
        )}
      <div className={cn('flex w-full flex-1 items-center justify-between', !isListView && 'px-[10px] py-[5px]')}>
        <div className="line-clamp-2 break-all">{fileName}</div>
        <Popover className="relative">
          <Popover.Button
            as={Button}
            variant="text"
            color="primary"
            containerStyle="flex size-[38px] items-center justify-center rounded-full"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <HiOutlineDotsVertical />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className="absolute right-0 top-[48px] z-[5] min-w-[160px] bg-white text-[12px] shadow-card"
            >
              {({ close }) => (
                <>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      onPreview();
                      close();
                    }}
                  >
                    <FaRegEye />
                    <div className="text-text-primary">預覽</div>
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setRenameModalVisible(true);
                      close();
                    }}
                  >
                    <FiEdit />
                    <div className="text-text-primary">更改名稱</div>
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      shareOnPress(
                        {
                          folderType,
                          driveType,
                          shopId,
                          path: dir === ''
                            ? `${fileName}`
                            : `${dir}/${fileName}`,
                        },
                      );
                      close();
                    }}
                  >
                    <BiShare />
                    <div className="text-text-primary">分享檔案</div>
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={async (e) => {
                      e.stopPropagation();
                      try {
                        await fileOnCopy({ ...data, shopId });
                        await getDriveData();
                        toast.success('建立副本成功');
                      } catch {
                        toast.error('建立副本失敗');
                      }
                      close();
                    }}
                  >
                    <MdContentCopy />
                    <div className="text-text-primary">建立副本</div>
                  </Button>
                  {fileType !== 'other' ? (
                    <Button
                      variant="text"
                      color="primary"
                      disabled={copyLoading}
                      containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                      onClick={async (e) => {
                        e.stopPropagation();
                        try {
                          setCopyLoading(true);
                          if (fileType === 'image') {
                            await copyImageLink({ ...data, shopId });
                          } else {
                            await copyVideoLink({ ...data, shopId });
                          }
                        } finally {
                          setCopyLoading(false);
                        }
                      }}
                    >
                      {copyLoading ? <Spinner size="sm" /> : <IoIosLink />}
                      <div className="text-text-primary">
                        複製
                        {fileType === 'image' ? '圖片' : '影片'}
                        網址
                      </div>
                    </Button>
                  ) : null}
                  <Button
                    variant="text"
                    color="primary"
                    containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMoveToModalVisible(true);
                      close();
                    }}
                  >
                    <MdOutlineDriveFileMove />
                    <div className="text-text-primary">移至</div>
                  </Button>
                  <a
                    color="primary"
                    className="flex w-full items-center gap-[8px] px-[10px] py-[8px] text-left hover:bg-brand-primary/10"
                    href={getDriveFilePreviewLink(
                      folderType,
                      dir,
                      fileName,
                      shopId,
                    )}
                    onClick={async (e) => {
                      e.stopPropagation();
                      close();
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MdOutlineDownload className="text-brand-primary" />
                    <div className="text-text-primary">下載</div>
                  </a>
                  <div className="border-t border-t-border-brand">
                    <Button
                      variant="text"
                      color="attention"
                      containerStyle="w-full py-[8px] text-left flex items-center gap-[8px] px-[10px]"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await fileOnDelete({ ...data, shopId });
                        await getDriveData();
                        close();
                      }}
                    >
                      <BiTrash />
                      <div className="text-brand-attention">移除</div>
                    </Button>
                  </div>
                </>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>

        <DriveFolderFormModal
          visible={renameModalVisible}
          loading={loading}
          onClose={() => { setRenameModalVisible(false); }}
          hiddenImageField
          initialValue={{
            name: fileName,
            image: [],
          }}
          onSubmit={async ({ name, image }) => {
            try {
              setLoading(true);
              await fileOnRename(data, image, name, false, shopId);
              await getDriveData();
              toast.success('更改名稱成功');
            } catch {
              toast.error('更改名稱失敗');
            } finally {
              setLoading(false);
            }
          }}
        />

        <DriveMoveToModal
          visible={moveToModalVisible}
          loading={moveToModalLoading}
          shopId={shopId}
          onClose={() => { setMoveToModalVisible(false); }}
          onSubmit={async (newDir) => {
            try {
              setMoveToModalLoading(true);
              await moveRenameDocument({
                source: {
                  folderType,
                  driveType,
                  shopId,
                  path: `${dir}/${fileName}`,
                },
                dest: {
                  ...newDir,
                  path: `${newDir.path}/${fileName}`,
                },
              });
              toast.success('移動成功');
              setMoveToModalVisible(false);
              getDriveData();
            } catch {
              toast.error('移動失敗');
            } finally {
              setMoveToModalLoading(false);
            }
          }}
        />
      </div>
    </button>
  );
};
