import {
  CSSObjectWithLabel,
  ControlProps,
  GroupBase,
  OptionProps,
} from 'react-select';

import COLORS from './Colors';

export const SELECT_STYLE = {
  control: (
    base: CSSObjectWithLabel,
    state: ControlProps<any, any, GroupBase<any>>,
  ) => ({
    ...base,
    minHeight: '46px',
    borderRadius: '6px',
    background: state.isDisabled ? COLORS.bg.disabled : undefined,
    borderColor: COLORS.border.brand,
    borderWidth: '1.24px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: COLORS.border.brand,
    },
    paddingLeft: 6,
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    overflow: 'hidden',
    zIndex: 20,
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  option: (
    base: CSSObjectWithLabel,
    state: OptionProps<any, false, GroupBase<any>>,
  ) => ({
    ...base,
    backgroundColor: state.isSelected ? COLORS.brand.primary : 'white',
    '&:hover': {
      backgroundColor: COLORS.brand.primary,
      color: 'white',
    },
  }),
  indicatorSeparator: (base: CSSObjectWithLabel) => ({
    ...base,
    display: 'none',
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: COLORS.text.light,
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    color: COLORS.text.primary,
  }),
};

export const SELECT_FILTER_STYLE = {
  control: (
    base: CSSObjectWithLabel,
    state: ControlProps<any, any, GroupBase<any>>,
  ) => ({
    ...base,
    borderColor: state.isFocused ? COLORS.border.brand : COLORS.border.brand,
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: COLORS.border.brand,
    },
    minHeight: 22,
    height: 22,
    borderBottom: 1,
    border: 0,
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    overflow: 'hidden',
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  option: (
    base: CSSObjectWithLabel,
    state: OptionProps<any, false, GroupBase<any>>,
  ) => ({
    ...base,
    backgroundColor: state.isSelected ? COLORS.brand.primary : 'white',
    '&:hover': {
      backgroundColor: !state.isSelected
        ? COLORS.brand.primary
        : COLORS.brand.primary,
      color: 'white',
    },
  }),
  indicatorSeparator: (base: CSSObjectWithLabel) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    placeSelf: 'self-start',
    padding: '0 0px',
    height: 22,
  }),
  input: (base: CSSObjectWithLabel) => ({
    ...base,
    margin: '0px',
    padding: '0px 0px',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    margin: '0px 0px',
    color: COLORS.text.light,
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    margin: '0px 0px',
  }),
};
