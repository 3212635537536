import { Menu, Popover, Transition } from '@headlessui/react';
import {
  Fragment, ReactNode, useCallback, useMemo,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { AiTwotoneWarning } from 'react-icons/ai';
import { BiExport } from 'react-icons/bi';
import { MdFileDownload } from 'react-icons/md';
import {
  RiDeleteBin6Line,
  RiFileCopy2Line,
  RiFileExcel2Fill,
  RiPencilLine,
  RiSave3Line,
} from 'react-icons/ri';
import { TbDotsVertical, TbPlus } from 'react-icons/tb';
import { toast } from 'react-toastify';

import { APP_NAME } from 'app/configs';
import { TOOLBAR_HEIGHT } from 'app/constants';
import cn from 'app/utils/ClassName';

import Button from '../Button';
import { ProgressBar } from '../ProgressBar';

interface CustomLinkButton {
  title: ReactNode;
  href: string;
}

// TODO: only keep create and delete
interface Props {
  title: string;
  loading?: boolean;
  createBtnHref?: string;
  createBtnOnClick?: () => void;
  copyBtnHref?: string;
  confirmDeleteMessage?: string;
  isOrderChanged?: boolean;
  orderOnSave?: () => void;
  deleteBtnOnClick?: () => void;
  updateBtnNode?: ReactNode;
  updateBtnDisable?: boolean;
  updateBtnOnClick?: () => void;
  updateForm?: ReactNode;
  copyLinkBtns?: CustomLinkButton[];
  exportHref?: string;
  downloadTemplateHref?: string;
  uploadExcelOnClick?: (file: File) => void;
  customNode?: ReactNode;
  filterNode?: ReactNode;
}

export const ToolBar = ({
  title,
  loading,
  createBtnHref,
  createBtnOnClick,
  copyBtnHref,
  confirmDeleteMessage,
  isOrderChanged,
  orderOnSave,
  deleteBtnOnClick,
  updateBtnNode,
  updateBtnDisable = false,
  updateBtnOnClick,
  updateForm,
  copyLinkBtns,
  exportHref,
  downloadTemplateHref,
  uploadExcelOnClick,
  customNode,
  filterNode,
}: Props) => {
  // TODO: refactor this
  const hasButton = useMemo(
    () => createBtnHref
    || createBtnOnClick
    || copyBtnHref
    || confirmDeleteMessage
    || isOrderChanged
    || orderOnSave
    || deleteBtnOnClick
    || updateBtnNode
    || updateBtnOnClick
    || updateForm
    || copyLinkBtns
    || exportHref
    || downloadTemplateHref
    || uploadExcelOnClick
    || customNode,
    [
      confirmDeleteMessage,
      copyBtnHref,
      copyLinkBtns,
      createBtnHref,
      createBtnOnClick,
      customNode,
      deleteBtnOnClick,
      downloadTemplateHref,
      exportHref,
      isOrderChanged,
      orderOnSave,
      updateBtnNode,
      updateBtnOnClick,
      updateForm,
      uploadExcelOnClick,
    ],
  );

  const buttonGroup = useCallback(() => (
    <div className="flex flex-col-reverse items-center gap-[4px] non-mobile:flex-row">
      {orderOnSave && (
      <Button
        type="button"
        variant="text"
        color="primary"
        containerStyle={`px-2 py-2 flex items-center justify-start non-mobile:justify-center gap-[4px] ${
          !isOrderChanged ? 'opacity-50' : ''
        }`}
        onClick={orderOnSave}
        disabled={!isOrderChanged}
      >
        <RiSave3Line className="size-5" />
        <div className="mt-[2px] text-[16px] leading-[16px]">
          儲存排序
        </div>
      </Button>
      )}

      {customNode}

      {exportHref && (
      <a
        target="_black"
        rel="noreferrer"
        className="flex w-full items-center justify-start gap-[4px] p-2 text-brand-primary hover:bg-brand-primary/10 non-mobile:w-auto non-mobile:justify-center"
        href={exportHref}
      >
        <BiExport className="size-5" />
        <div className="mt-[2px] text-[16px] leading-[16px]">匯出</div>
      </a>
      )}
      {downloadTemplateHref && (
      <a
        target="_black"
        rel="noreferrer"
        className="flex w-full items-center justify-start gap-[4px] p-2 text-brand-primary hover:bg-brand-primary/10 non-mobile:w-auto non-mobile:justify-center"
        href={downloadTemplateHref}
      >
        <MdFileDownload className="size-5" />
        <div className="mt-[2px] text-[16px] leading-[16px]">
          下載範本
        </div>
      </a>
      )}
      {uploadExcelOnClick && (
      <>

        <input
          id="uploadExcel"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          className="hidden"
          onChange={(e) => {
            if (e.currentTarget.files?.[0]) {
              uploadExcelOnClick(e.currentTarget.files[0]);
            }
          }}
        />
        <label
          htmlFor="uploadExcel"
          className="flex w-full cursor-pointer items-center justify-center gap-[4px] p-2 text-brand-primary hover:bg-brand-primary/10 non-mobile:w-auto"
        >
          <RiFileExcel2Fill className="size-5" />
          <div className="mt-[2px] text-[16px] leading-[16px]">
            上載列表
          </div>
        </label>
      </>
      )}

      {copyLinkBtns?.map(({ href, title: btnTitle }, index) => (
        <button
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(href);
            toast.success('複製成功');
          }}
          className="w-full text-brand-primary non-mobile:w-auto"
          key={`custom-link-button-${index}`}
        >
          {btnTitle}
        </button>
      ))}

      {/* //TODO: confirm delete modal for mobile  */}
      {deleteBtnOnClick && (
      <Popover className="relative w-full non-mobile:w-auto">
        <>
          <Popover.Button
            type="button"
            variant="text"
            color="primary"
            onClick={() => null}
            containerStyle="px-2 py-2 flex items-center justify-start non-mobile:justify-center w-full gap-[4px]"
            as={Button}
          >
            <RiDeleteBin6Line className="size-5" />
            <div className="text-[16px] leading-[16px]">
              刪除
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-3 w-[250px] bg-transparent">
              <div className="flex flex-col items-center overflow-hidden rounded-md bg-white p-4 shadow-lg ring-1 ring-black/5">
                <AiTwotoneWarning className="size-[100px] text-yellow-200" />
                <p>{confirmDeleteMessage}</p>
                <div className="flex w-full items-center justify-end pt-4">
                  <Popover.Button
                    as={Button}
                    type="button"
                    variant="contained"
                    color="secondary"
                    href={copyBtnHref}
                    containerStyle="px-2 flex items-center justify-center"
                    onClick={deleteBtnOnClick}
                  >
                    確定
                  </Popover.Button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      </Popover>
      )}

      {updateBtnOnClick && updateForm && (
      <Popover className="relative">
        <>
          <Popover.Button
            type="button"
            variant="text"
            color="primary"
            disabled={updateBtnDisable}
            onClick={() => null}
            containerStyle={cn(
              'flex items-center justify-start non-mobile:justify-center p-2 w-full non-mobile:w-auto gap-[4px]',
            )}
            as={Button}
          >
            {updateBtnNode || (
            <>
              <RiPencilLine className="size-5" />
              <div className="mt-[2px] text-[16px] leading-[16px]">
                更新
              </div>
            </>
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-3 w-[300px] bg-transparent">
              <div className="flex flex-col items-center rounded-md bg-white py-[16px] shadow-lg ring-1 ring-black/5">
                <div className="w-full px-[16px]">{updateForm}</div>
                <div className="flex w-full items-center justify-end border-t border-border-brand px-[16px] pt-4">
                  <Popover.Button
                    as={Button}
                    type="button"
                    variant="contained"
                    color="secondary"
                    href={copyBtnHref}
                    containerStyle="px-2 flex items-center justify-center"
                    onClick={updateBtnOnClick}
                  >
                    確定
                  </Popover.Button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      </Popover>
      )}

      {copyBtnHref && (
      <Button
        type="button"
        variant="text"
        color="primary"
        href={copyBtnHref}
        containerStyle="px-2 py-2 flex items-center justify-start non-mobile:justify-center w-full non-mobile:w-auto gap-[4px]"
      >
        <RiFileCopy2Line className="size-5" />
        <div className="mt-[2px] text-[16px] leading-[16px]">複製</div>
      </Button>
      )}

      {(createBtnHref || createBtnOnClick) && (
      <Button
        type="button"
        variant="text"
        color="primary"
        href={createBtnHref}
        onClick={createBtnOnClick}
        containerStyle="px-2 py-2 flex items-center justify-start non-mobile:justify-center w-full non-mobile:w-auto gap-[4px]"
      >
        <TbPlus className="size-5" />
        <div className="mt-[2px] text-[16px] leading-[16px]">新增</div>
      </Button>
      )}
    </div>
  ), [
    confirmDeleteMessage,
    copyBtnHref,
    copyLinkBtns,
    createBtnHref,
    createBtnOnClick,
    customNode,
    deleteBtnOnClick,
    downloadTemplateHref,
    exportHref,
    isOrderChanged,
    orderOnSave,
    updateBtnDisable,
    updateBtnNode,
    updateBtnOnClick,
    updateForm,
    uploadExcelOnClick,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {title}
          {' '}
          |
          {' '}
          {APP_NAME}
        </title>
      </Helmet>
      <div
        style={{ minHeight: TOOLBAR_HEIGHT }}
        className="relative w-full border-b border-border-brand bg-white px-4"
      >
        <div className="flex h-full items-center justify-between">
          <h2 className="font-bold text-text-primary">{title}</h2>

          <div className="flex items-center">
            <div className="non-mobile:hidden">
              {filterNode}
            </div>
            {hasButton ? (
              <Menu as="div" className="relative flex non-mobile:hidden">
                <Menu.Button className="inline-flex w-full justify-center">
                  <TbDotsVertical className="size-[20px] text-text-secondary" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 top-[32px] z-10 w-max min-w-[120px] rounded-[10px] border bg-white p-[16px]">
                    {buttonGroup()}
                  </Menu.Items>
                </Transition>
              </Menu>
            ) : null}

            <div className="hidden non-mobile:flex">
              {buttonGroup()}
            </div>
          </div>
        </div>
        {loading && (
        <div className="absolute inset-x-0 bottom-0 w-full">
          <ProgressBar />
        </div>
        )}
      </div>
    </>
  );
};
