import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PAGE_SIZE } from 'app/configs';
import { Filter, ListResult } from 'app/models/Common';

export interface UseTableDataParams<R, P extends Filter<R>> {
  call: (params: P) => Promise<ListResult<R>>;
  filterVariableArray: (keyof P)[];
  limit?: number;
  initialParams?: P;
}

export const useTableData = <R, P extends Filter<R>>({
  call,
  filterVariableArray,
  limit,
  initialParams,
}: UseTableDataParams<R, P>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const pageNumber = parseInt(searchParams.get('page') ?? '1', 10);
  const [dataList, setDataList] = useState<R[]>([]);
  const [filter, setFilter] = useState<P | undefined>(initialParams);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const isFirstLoad = useRef<boolean>(true);

  const getDataList = useCallback(async () => {
    if (!isFirstLoad.current && filter) {
      try {
        setLoading(true);
        const { count: tempCount, rows } = await call(filter);
        setDataList(rows);
        setCount(tempCount);
      } catch (err: any) {
        toast.error('請檢查你的網絡。');
      } finally {
        setLoading(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call, filter]);

  useEffect(() => {
    getDataList();
  }, [getDataList]);

  useEffect(() => {
    let tempFilter: any = { limit: limit || PAGE_SIZE, offset: 0 };
    if (filter) {
      tempFilter = { ...filter };
    }
    if (filterVariableArray) {
      filterVariableArray?.forEach((key) => {
        const value = searchParams.getAll(key.toString()) as P[keyof P];
        tempFilter[key] = filter && isFirstLoad.current && filter[key] ? filter[key] : value;
      });
    }
    if (pageNumber) {
      tempFilter.offset = (pageNumber - 1) * (limit || PAGE_SIZE);
      setPage(pageNumber);
    }
    Object.keys(tempFilter).forEach((key) => {
      if (Array.isArray(tempFilter[key]) && tempFilter[key].length === 1) {
        [tempFilter[key]] = tempFilter[key];
      }
    });
    // compare filter and tempFilter, only setFilter when they are different
    if (JSON.stringify(filter) !== JSON.stringify(tempFilter)) {
      setFilter(tempFilter);
    }
    isFirstLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    loading,
    filter,
    count,
    dataList,
    page,
    setFilter,
    setDataList,
    setPage,
    getDataList,
  } as const;
};
